import { useHookstate, Downgraded } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import equal from 'fast-deep-equal/es6/react';
import SecureStorage from '../Utils/SecureStorage';

export const usePersistState = (state, storageKey) => {
    try {
        const _state = useHookstate(state);
        if (storageKey != null)
            attachState(_state, storageKey)
        return _state.attach(Downgraded)
    }
    catch (error) {
        console.log(error)
        return null
    }
}
export const attachState = (state, storageKey) => {
    if (storageKey != null)
        state.attach(Persistence(storageKey))
}
export const manuallySaveState = (value, storageKey) => {
    SecureStorage().setItem(storageKey, JSON.stringify(value))
}

export const deepSetState = (state, value, persist = false, storageKey = '',forceSetState=false) => {
    try {

        if (persist && storageKey!='' && localStorage.getItem(storageKey)==null)
        {
            manuallySaveState(value, storageKey)
            return
        }
        if (!equal(state.get(), value) || forceSetState) {
            state.set(value)
            if (persist && storageKey.trim().length > 0) {
                manuallySaveState(value, storageKey)
            }
            else if (storageKey.trim().length == 0 && persist)
                throw Error('storageKey cannot be null')
        }
    }
    catch (error) {
        console.log('deepSetState error', error)
    }
}



