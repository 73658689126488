import React, { Suspense, useEffect, useState } from "react";
import "animate.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import {
  HashRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  customToast,
  getRemoteConfig,
  isDesktop,
  isHybrid,
  logErrorToServer,
  manageNotifications,
  setRemoteConfig,
} from "./Utils/General";

import SlideRoutes from "react-slide-routes";
import { getAuthorization, useApi } from "./Api/index";
import {
  getRemoteConfig as getRemoteConfigAction,
  getServerTime,
} from "./Api/Actions/Init";
import { deepSetState, usePersistState, UserState } from "./State";

import { UserStocks } from "./State/Stocks";

import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import "firebase/compat/analytics";
import localforage from "localforage";
import { cssTransition, ToastContainer } from "react-toastify";
import { ThemeState } from "./State/Theme";
import "react-loading-skeleton/dist/skeleton.css";
import { getUserProfile, setFcmToken } from "./Api/Actions/Authentication";

import MemberShipModal from "./Components/MemberShipModal";
import { AppSectionTypeIds } from "./Utils/Constants";
import { isIOS, isMobile } from "react-device-detect";
import { validateCafebazaar } from "./Api/Actions/user";
import { setBaseUrl } from "./Api/Constants";
import { ReactComponent as CloseButton } from "./assets/img/multiplied.svg";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ModalWrapper from "./Components/General/ModalWrapper";
import ForceUpdateModal from "./Components/TopMenu/ForceUpdateModal";
import HamrahButton from "./Components/General/HamrahButton";
import { useHookstate } from "@hookstate/core";
import { IsLoggedInState } from "./State/IsLoggedIn";
import OldVersionModal from "./Components/TopMenu/OldVersionModal";
import HamrahCheckBox from "./Components/General/HamrahCheckBox";
import { ShowRateModalState } from "./State/RateModalState";

import UpdateItem from "./Views/Profile/Views/UpdateModal/UpdateItem";
import InstallService from "./Views/InstallService";
import Splash from "./Views/Splash";
import routes from "./routes";
import ThemedSuspense from "./Components/ThemedSuspense";

const pjson = require("./../package.json");

setBaseUrl(localStorage.getItem("BURL"));

function App() {
  const [_, callApi] = useApi(null, false, true);
  const [loadingAll, setLoadingAll] = useState(true);
  const me = usePersistState(UserState, "UserState").get();
  const remoteConfig = getRemoteConfig();

  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showPwaUpdateModal, setShowPwaUpdateModal] = useState(false);
  const [showCantUseAppModal, setShowCantUseAppModal] = useState(false);
  const [showUpdateFeaturesModal, setShowUpdateFeaturesModal] = useState(false);
  const [dontShowRateModal, setDontShowRateModal] = useState(false);
  const isLoggedIn = useHookstate(IsLoggedInState).get();
  const showRateModal = useHookstate(ShowRateModalState).get();

  // const [showLandScapeModal, setShowLandScapeModal] = useState(false);
  // const { isLandscape } = useMobileOrientation()
  const [notificationModal, setShowNotificationModal] = useState({
    show: false,
    message: "",
  });
  const customSlide = cssTransition({
    enter: isMobile
      ? "animate__animated animate__fadeInUp"
      : "animate__animated animate__fadeInRight",
    exit: isMobile
      ? "animate__animated animate__fadeOutDown"
      : "animate__animated animate__fadeOutRight",
  });
  const versionCheck = async (versionObject) => {
    try {
      if (localStorage.getItem("THIS_VERSION") == versionObject?.version) {
        setTimeout(() => {
          localStorage.removeItem("THIS_VERSION");
        }, 8000);
        return;
      }

      if (pjson?.version < versionObject?.version) {
        if (versionObject?.force) {
          try {
            window.caches
              .keys()
              .forEach((c) => caches.delete(c))(
                navigator.serviceWorker.getRegistrations()
              )
              .forEach((registration) => {
                registration.unregister();
              });
            localStorage.removeItem("THIS_VERSION");
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              localStorage.setItem("THIS_VERSION", versionObject?.version);
              window.location.reload(true);
            }, 250);
          }
        }
      }
    } catch (errors) {}
  };

  const onWindowFocus = () => {
    try {
      if (isHybrid()) return;

      let shouldNavigate = true;
      localforage.getItem("clickedNotification", (error, data) => {
        if (!data) shouldNavigate = false;
        localforage.getItem("allNotifications", (error, notifs) => {
          if (!notifs || notifs?.length <= 0) return;
          if (!data) {
            const _lastNotif = notifs[notifs.length - 1];
            if (!_lastNotif || _lastNotif?.handled == true) return;
            setHandled();
            const action = manageNotifications(_lastNotif, true);
            handleActions(action);

            return;
          }
          localforage.removeItem("clickedNotification");

          for (let notif of notifs) {
            const _data = JSON.parse(notif?.data?.data);
            if (_data.id == data.id) {
              setHandled();
              const action = manageNotifications(notif, true);
              handleActions(action);
              break;
            }
          }
        });
      });
    } catch (e) {}
  };
  const setHandled = () => {
    try {
      localforage.getItem("allNotifications", (error, notifs) => {
        if (!notifs) return;
        const _notifs = [...notifs];
        const handledNotifs = _notifs?.map((n) => ({ ...n, handled: true }));
        localforage.setItem("allNotifications", handledNotifs);
      });
    } catch (er) {}
  };
  const handleActions = (action) => {
    try {
      switch (action?.type?.toLowerCase()) {
        case "appurl".toLowerCase():
          window.location.href = `/#/${action?.value}`;
          break;
        case "viewsymbol".toLowerCase():
          window.location.href = `/#/viewsymbol/${action?.value}/0`;
          break;
        case "weburl".toLowerCase():
          window.location.href = `${action?.value}`;
          break;
        case "showmodal".toLowerCase():
          setShowNotificationModal({ show: true, message: action?.value });
          break;
        case "showmembership".toLowerCase():
          setShowMembershipModal(true);
          break;
      }
    } catch (err) {}
  };
  const onNativeCalled = (event) => {
    if (event.detail.event == "OnNotificationOnBackground") {
      manageNotifications(event?.detail?.value, true);
    }
    if (event.detail.event == "OnNotificationOnForeground") {
      manageNotifications(event?.detail?.value, false);
    }
  };

  // const onShowRateAppModalCalled = (event) => {

  //   if (localStorage.getItem('showRateAppModal') == 'true') return
  //   setTimeout(()=>setShowRateModal(true),1500)

  // }

  useEffect(() => {
    if (!isHybrid()) {
      try {
        // const firebaseConfig = {
        //   apiKey: "AIzaSyA7pnELMwcNzUVM0hqv41PsIfsbbq3XbAA",
        //   authDomain: "pwatest-c232a.firebaseapp.com",
        //   databaseURL: "https://pwatest-c232a.firebaseio.com",
        //   projectId: "pwatest-c232a",
        //   storageBucket: "pwatest-c232a.appspot.com",
        //   messagingSenderId: "71433774464",
        //   appId: "1:71433774464:web:ac1819b165630c650a0d65",
        //   measurementId: "G-E949CY8ZX0"
        // };

        const firebaseConfig = {
          apiKey: "AIzaSyCmdKcKIU6m0xWEuVDBv8jrFKcrY2WIFE0",
          authDomain: "hamrahbours-app.firebaseapp.com",
          databaseURL: "https://hamrahbours-app.firebaseio.com",
          projectId: "hamrahbours-app",
          storageBucket: "hamrahbours-app.appspot.com",
          messagingSenderId: "663315126998",
          appId: "1:663315126998:web:b125583a6c1a39c1f04777",
          measurementId: "G-15DNZXMKFV",
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        const messaging = firebase.messaging();

        messaging.onMessage((payload) => {
          console.log("Message received. ", payload);
          const action = manageNotifications(payload, false, () => {
            handleActions(action);
          });
          setHandled();
        });

        // localforage.getItem('BG_MSG', (error, data) => {

        //   console.log('previous notification ===> ', data)
        //   if (!data) return

        //   localforage.removeItem('BG_MSG')
        //   manageNotifications(data, true)

        // })
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.

        // BJz5u9Eg7uvgUjfnaBZbB5z7VWeFrIpcm2NGeu9TM4pJVvBe09smBWm8iJh42t5odX9JYd8bOuWINsdJ4zsA1 - 0

        messaging
          .getToken({
            vapidKey:
              "BAt8FVPfZ-d6dwmaoN11-YhEXjlVYM1TdyLzgqXf_Dr0yaVkOy5lsQmtQEC9dMICzfVapDFmUrxVMlSSpD8yGsc",
          })
          .then(async (currentToken) => {
            if (isLoggedIn) {
              const response = await callApi(
                  setFcmToken({ Token: currentToken }),
                  true,
                  true
              );
              if (response.success) localStorage.removeItem("FB_TK");
              else localStorage.setItem("FB_TK", currentToken);
              // localStorage.setItem('FB_TK', currentToken)
              console.log("setFcmToken======>", response, currentToken);
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            console.log("Error retrieving registration token. ", err);
          });
      } catch (e) {
        console.log(e);
      }
    }

    if (localStorage.getItem("UserStateOld") != null) {
      const oldStorage = JSON.parse(localStorage.getItem("UserStateOld"));
      deepSetState(UserState, oldStorage, true, "UserState");
    } else if (UserState.get()?.Registered == false) {
      deepSetState(UserState, "", true, "UserState");
      deepSetState(UserStocks, "", true, "STOCK");
      localStorage.setItem("ACCEPTED_SHOW", "NO");
      setLoadingAll(false);
      window.location.href = "/#/otp";

      return;
    }
    window.addEventListener("beforeinstallprompt", function (e) {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
    });

    setLoadingAll(true);
    console.log("token==>" + getAuthorization());
    if (getAuthorization() == null) {
      setLoadingAll(false);
      return;
    }
    (async () => {
      const response = await callApi(
        getRemoteConfigAction({ JsonParse: true, Key: ["FirstPage"] })
      );

      if (response.success) {
        await versionCheck(response?.data?.PWA);

        const main_remote = response.data.find((r) => r.Key == "FirstPage");
        setRemoteConfig(main_remote.Value);

        const responseMe = await callApi(getUserProfile());
        if (responseMe.success) {
          const responseServerTime = await callApi(getServerTime());
          if (responseServerTime.success) {
            const oldStorage = JSON.parse(localStorage.getItem("UserStateOld"));
            IsLoggedInState.set(true);
            localStorage.removeItem("UserStateOld");
            deepSetState(
              UserState,
              {
                ...me,
                Time: responseServerTime.data.Time,
                ...responseMe.data,
                ...oldStorage,
              },
              true,
              "UserState"
            );

            setLoadingAll(false);

            onWindowFocus();
            window.onfocus = onWindowFocus;

            if (isHybrid()) {
              window.onShowRateModalCall = () => {
                if (localStorage.getItem("showRateAppModal") == "true") return;
                setTimeout(() => ShowRateModalState.set(true), 1500);
              };

              // window.removeEventListener('onRateAppModal', onShowRateAppModalCalled)
              // window.addEventListener('onRateAppModal',onShowRateAppModalCalled)

              try {
                const token_hybrid = localStorage.getItem("FB_TK_H");
                if (token_hybrid == null) return;
                const response = await callApi(
                  setFcmToken({ Token: token_hybrid }),
                  true,
                  true
                );
                if (response.success) {
                  localStorage.removeItem("FB_TK_H");
                } else localStorage.setItem("FB_TK_H", token_hybrid);

                const inAppPurchaseBazaar = localStorage.getItem(
                  "inAppPurchaseBazaar"
                );

                if (inAppPurchaseBazaar !== null) {
                  let inAppPurchaseData = JSON.parse(inAppPurchaseBazaar);

                  const { purchaseToken, productId, Amount } =
                    inAppPurchaseData;

                  const response = await callApi(
                    validateCafebazaar({
                      PurchaseToken: purchaseToken,
                      SubscriptionId: productId,
                      Amount: parseInt(Amount),
                    })
                  );

                  if (response.status == 200) {
                    const responseMe = await callApi(getUserProfile());
                    deepSetState(
                      UserState,
                      {
                        ...me,
                        ...responseMe.data,
                      },
                      true,
                      "UserState"
                    );

                    localStorage.removeItem("inAppPurchaseBazaar");
                  }
                  if (
                    response.status === 409 &&
                    !response.success &&
                    response.code === -12
                  ) {
                    localStorage.removeItem("inAppPurchaseBazaar");
                  }
                }

                window.removeEventListener("onNativeCalled", onNativeCalled);
                window.addEventListener("onNativeCalled", onNativeCalled);

                {
                  setTimeout(() => {
                    try {
                      window.Android.onListenerReady();
                    } catch (e) {}
                  }, 250);
                }
              } catch (error) {
                console.log(error);
              }
            }
          } else {
            logErrorToServer("time error" + JSON.stringify(responseServerTime));
            setLoadingAll(false);
          }
        } else {
          if (responseMe.code != -5) {
            logErrorToServer("me error" + JSON.stringify(responseMe));
          }
          setLoadingAll(false);
        }
      } else {
        console.log(response);
        if (response.code != -5) {
          logErrorToServer("remote config error" + JSON.stringify(response));
        }
        setLoadingAll(false);
      }
    })();
  }, []);
  createBrowserHistory();
  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      ThemeState.set("dark");
    } else {
      document.documentElement.classList.add("dark");
      ThemeState.set("dark");
    }

    window.matchMedia("(prefers-color-scheme: dark)").addListener(function (e) {
      if (localStorage.theme != undefined || localStorage.theme != null) return;
      e.matches && document.documentElement.classList.add("dark");
      e.matches && ThemeState.set("dark");
      !e.matches && document.documentElement.classList.remove("dark");
      !e.matches && ThemeState.set("light");
    });

    try {
      const search = "?" + window.location.href.split("?")[1];
      const sp = new URLSearchParams(search);
      const action = sp.get("action");
      if (action == null) return;

      if (action.toLowerCase() == "BePremiumPayment".toLowerCase()) {
        if (
          localStorage.getItem("UserState") == null ||
          localStorage.getItem("RC_FP") == null
        )
          return;

        if (sp.get("status") == "false") {
          setShowMembershipModal(true);

          customToast("error", "پرداخت انجام نشد");
        } else if (sp.get("status") == "true") {
          customToast("success", "پرداخت با موفقیت انجام شد");
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const updateApp = async () => {
    localStorage.setItem(
      "clickedOnUpdate",
      `${+localStorage.getItem("clickedOnUpdate") + 1}`
    );
    try {
      setUpdating(true);
      (await window.caches.keys()).forEach((c) => caches.delete(c));
      (await navigator.serviceWorker.getRegistrations()).forEach(
        (registration) => {
          registration.unregister();
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 250);
    }
  };

  useEffect(() => {
    const refreshCount = localStorage.getItem("refreshCount");
    const hasSeenPwaUpdate = localStorage.getItem("hasSeenPWAUpdate");
    if (pjson.version == remoteConfig?.PWA?.version) {
      localStorage.removeItem("refreshCount");
      localStorage.removeItem("clickedOnUpdate");
    }

    if (
      pjson.version != remoteConfig?.PWA?.version &&
      localStorage.getItem("clickedOnUpdate") == 2 &&
      isLoggedIn
    ) {
      setShowCantUseAppModal(true);
    }

    const timeout = setTimeout(
      () => {
        if (
          remoteConfig == null ||
          localStorage.getItem("clickedOnUpdate") == "2"
        )
          return;

        if (
          pjson.version != remoteConfig?.PWA?.version &&
          isLoggedIn &&
          !remoteConfig?.isSilentUpdate
        ) {
          if (
            hasSeenPwaUpdate != remoteConfig?.PWA?.version ||
            !remoteConfig?.canCancelPWAUpdate
          ) {
            localStorage.setItem(
              "hasSeenPWAUpdate",
              remoteConfig?.PWA?.version
            );
            setShowPwaUpdateModal(true);
          }
        }

        if (
          pjson.version != remoteConfig?.PWA?.version &&
          isLoggedIn &&
          remoteConfig?.isSilentUpdate &&
          refreshCount == 2
        ) {
          if (
            hasSeenPwaUpdate != remoteConfig?.PWA?.version ||
            !remoteConfig?.canCancelPWAUpdate
          ) {
            localStorage.setItem(
              "hasSeenPWAUpdate",
              remoteConfig?.PWA?.version
            );
            setShowPwaUpdateModal(true);
          }
        }

        if (
          pjson.version != remoteConfig?.PWA?.version &&
          isLoggedIn &&
          remoteConfig?.isSilentUpdate &&
          +refreshCount < 2
        ) {
          localStorage.setItem("refreshCount", `${+refreshCount + 1}`);
          updateApp();
        }
      },
      !remoteConfig?.canCancelPWAUpdate ? 1500 : 7000
    );

    if (
      pjson?.version == remoteConfig?.PWA?.version &&
      isLoggedIn &&
      !remoteConfig?.isSilentUpdate
    ) {
      if (
        (!localStorage.getItem("hasSeenUpdateFeatures") ||
          localStorage.getItem("hasSeenUpdateFeatures") != pjson.version) &&
        remoteConfig?.shouldSeeUpdateFeatures
      ) {
        setShowUpdateFeaturesModal(true);
        localStorage.setItem("hasSeenUpdateFeatures", `${pjson.version}`);
        clearTimeout(timeout);
      }

      // setShowPwaUpdateModal(true);
      // localStorage.setItem('hasSeenPWAUpdate', 'true');
    }

    return () => clearTimeout(timeout);
  }, [remoteConfig, isLoggedIn]);

  const onCancelRateModal = () => {
    if (dontShowRateModal) localStorage.setItem("showRateAppModal", "true");
    ShowRateModalState.set(false);
  };
  const onGotoRateWindow = () => {
    try {
      if (!isHybrid()) return;
      window.Android.onGotoRateWindow(localStorage.getItem("market"));
      localStorage.setItem("showRateAppModal", "true");
      ShowRateModalState.set(false);
    } catch (e) {}
  };
  return (
    <div className={"w-full"}>
      <InstallService />
      {loadingAll && <Splash />}
      {!loadingAll && (
        <HashRouter>
          <Switch>
            <CustomRouter />
          </Switch>
        </HashRouter>
      )}
      {showMembershipModal && (
        <MemberShipModal
          closemodal={() => setShowMembershipModal(false)}
          permissionType={AppSectionTypeIds.BASKET}
        />
      )}
      <ToastContainer
        role="alert"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        closeOnClick
        rtl={true}
        closeButton={({ closeToast }) => <CloseButton onClick={closeToast} />}
        transition={customSlide}
        draggable
        bodyClassName="font-bold"
      />
      <ModalWrapper
        isOpen={notificationModal.show}
        closemodal={() =>
          setShowNotificationModal({ show: false, message: "" })
        }
      >
        <div className={"p-3"}>
          <div className={"font-semibold text-title text-header text-center"}>
            {notificationModal.message}
          </div>
          <HamrahButton
            className={"mt-4"}
            onClick={() =>
              setShowNotificationModal({ show: false, message: "" })
            }
          >
            تایید
          </HamrahButton>
        </div>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showRateModal && isHybrid()}
        closemodal={() => ShowRateModalState.set(false)}
      >
        <div className={"p-0 text-title"}>
          <div className={"font-semibold text-header text-center"}>
            امتیاز دهی به همراه بورس
          </div>
          <div className="text-center px-4">
            کاربر گرامی، لطفا با ثبت نظر خود ما را در بهبود عملکرد "همراه بورس"
            یاری کنید.
          </div>
          <div className={"flex justify-center mt-4"}>
            <HamrahButton onClick={onGotoRateWindow} className={"min-w-48"}>
              امتیاز دهی
            </HamrahButton>
            <HamrahButton
              variant="cancel"
              className={"mr-2 min-w-24"}
              onClick={onCancelRateModal}
            >
              انصراف
            </HamrahButton>
          </div>
          <div className="flex justify-end mt-4 -mb-2">
            <HamrahCheckBox
              checked={dontShowRateModal}
              onClick={(checked) => setDontShowRateModal(checked)}
            >
              دیگر به من نمایش نده
            </HamrahCheckBox>
          </div>
        </div>
      </ModalWrapper>

      {remoteConfig?.pwaNewItem && (
        <ModalWrapper
          modalCss={"overflow-hidden  md:h-auto mx-auto"}
          // fullScreen={getTailwindScreenSize() == 'sm'}
          isOpen={showPwaUpdateModal}
          closemodal={() =>
            setShowPwaUpdateModal(!remoteConfig?.canCancelPWAUpdate)
          }
        >
          <ForceUpdateModal
            canCancel={remoteConfig?.canCancelPWAUpdate}
            onClick={updateApp}
            loading={updating}
            closemodal={() =>
              setShowPwaUpdateModal(!remoteConfig?.canCancelPWAUpdate)
            }
          />
        </ModalWrapper>
      )}
      <ModalWrapper
        modalCss="overflow-hidden md:h-auto mx-auto"
        closemodal={() => setShowCantUseAppModal(true)}
        isOpen={showCantUseAppModal}
      >
        <OldVersionModal />
      </ModalWrapper>
      {remoteConfig?.pwaNewItem && (
        <ModalWrapper
          modalCss={"overflow-hidden md:h-auto mx-auto"}
          // fullScreen={getTailwindScreenSize() == 'sm'}
          isOpen={showUpdateFeaturesModal}
          closemodal={() => setShowUpdateFeaturesModal(false)}
        >
          <div
            className={
              "flex flex-col items-center justify-between w-full flex-1 h-full  md:pb-0 overflow-hidden"
            }
          >
            <div className={" flex-1 overflow-auto h-full w-full pb-5 md:pb-0"}>
              <div
                className={"border border-borderSolid rounded-2xl p-5 pt-0 m-5"}
              >
                <div
                  className={
                    "text-brand font-black text-header text-center mb-8 mt-5"
                  }
                >
                  بروز رسانی با موفقیت انجام شد
                </div>
                <div
                  className={
                    "text-title font-semibold text-body text-center mb-5"
                  }
                ></div>

                <UpdateItem
                  version={remoteConfig?.pwaNewItem[0]?.versionName}
                  isLatestVersion={false}
                  features={remoteConfig?.pwaNewItem[0]?.changes}
                />
              </div>
              <HamrahButton
                className={"mx-auto w-1/2"}
                contentClassName={"px-2"}
                onClick={() => setShowUpdateFeaturesModal(false)}
              >
                متوجه شدم
              </HamrahButton>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
}

const Div = ({ children, ...rest }) => (
  <div {...rest}>{<span>{children}</span>}</div>
);
const CustomRouter = () => {
  const location = useLocation();
  const RouteManager =
    !isDesktop() && !isIOS && !isHybrid() ? SlideRoutes : Div;
  const history = useHistory();

  useEffect(() => {
    const sp = new URLSearchParams(window.location.search);
    const action = sp.get("action");

    if (action?.toLowerCase() == "sejampayment") {
      const status = sp.get("status");
      if (status.toLowerCase() == "false") {
        customToast("error", "پرداخت با خطا مواجه شد");
        const meli = localStorage.getItem("TMP_ID");
        localStorage.removeItem("TMP_ID");
        history.push({ pathname: `/ehrazpayment/${meli}`, search: "" });
      } else if (status == "true") {
        customToast("success", "پرداخت موفقیت آمیز بود");

        const meli = localStorage.getItem("TMP_ID");
        localStorage.removeItem("TMP_ID");
        history.push({ pathname: `/ehrazmelliserial/${meli}`, search: "" });
      }
    }

    try {
      if (isHybrid()) window?.Android?.endUpdatingApp();
    } catch (e) {}
  }, []);

  return (
    <Suspense fallback={<ThemedSuspense />}>
      <RouteManager timing={"ease-in-out"} duration={200} location={location}>
        {routes.map(({ component, exact, path }) => (
          <Route key={path} component={component} exact={exact} path={path} />
        ))}
      </RouteManager>
    </Suspense>
  );
};
export default App;
