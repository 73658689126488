import Spinner from "react-spinner-material";
import { AnimatePresence, motion } from "framer-motion";

const CheckBoxComponent = ({
                             checked = false,
                             hasError = false,
                             disabled = false,
                             loading = false,
                             onClick = () => {},
                             children,
                             className = "",
                           }) => {
  return (
      <div onClick={() => onClick(!checked)}>
        <div
            className={`${
                disabled ? "" : "cursor-pointer"
            } flex flex-row justify-center items-center cursor-default select-none ${className}`}
        >
          <div
              className={`rounded-md border-c1 w-[26px] h-[26px] scale-90 justify-center items-center flex ${
                  checked
                      ? hasError
                          ? "border-error"
                          : "border-accent-negative"
                      : "border-details"
              } ${disabled ? "pointer-events-none border-details" : ""} `}
          >
            <AnimatePresence>
              {!loading && checked && (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="#3B82F6"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  >
                    <motion.path
                        initial={{
                          pathLength: 0,
                        }}
                        animate={{
                          pathLength: 1,
                        }}
                        transition={{
                          duration: 0.3,
                        }}
                        exit={{
                          pathLength: 0,
                        }}
                        d="M20 6 9 17l-5-5"
                    />
                  </svg>
              )}
            </AnimatePresence>
            {loading && (
                <div>
                  <Spinner radius={12} stroke={1} color="var(--Brand-Default)" />
                </div>
            )}
          </div>
          <div className={"font-medium text-sm px-2 text-title"}>{children}</div>
        </div>
      </div>
  );
};

export default CheckBoxComponent;
