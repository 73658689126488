import React from 'react';
import propTypes from 'prop-types';
import pjson from './../../package.json'
import { InternalCallApi } from '../Api/index';
import AppError from '../Views/Errors/AppError';
import { EventActions } from '../Api/Actions/Event';
import { browserName, browserVersion, mobileModel, mobileVendor, osName, osVersion } from 'react-device-detect'
import { appendVersionSymbol, getRemoteConfig, isHybrid } from '../Utils/General';
import { cq } from '../index';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, hasError: false, errorInfo: null };
    }

    static getDerivedStateFromError = () => {
        return { hasError: true };
    };

    async componentDidCatch(error, errorInfo) {

        this.setState({
            error: error,
            errorInfo: errorInfo,
            hasError: true
        });


        if (process.env.NODE_ENV != 'development') {

            let extra=[]
            extra.push({remote:getRemoteConfig()})
            extra.push({ userstate: localStorage.getItem("UserState") });
            for (const item of cq) {
                extra.push({call:item});
            }

            await InternalCallApi(EventActions.Add({
                OS: mobileVendor + ' ' + mobileModel + '  ' + osName + ' ' + osVersion + '-' + browserName + '-' + browserVersion,
                StackTrace: JSON.stringify({
                    componentStack: errorInfo?.componentStack,
                    errorStack: error.stack,
                    errorMessage: error.toString(),
                    requests: extra
                }),
                Version: appendVersionSymbol(pjson.version, true),
                Type: isHybrid() ? 'Hybrid-Error' : 'PWA-Error',
                Message: isHybrid() ? 'Hybrid-Error' : 'PWA-Error'
            }), true)
        }
    }

    render() {
        if (this.state.hasError) {
            return <AppError info={this.state.errorInfo} />;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary

ErrorBoundary.propTypes = {
    children: propTypes.oneOfType([
        propTypes.node,
        propTypes.arrayOf(propTypes.node),
    ]).isRequired,
};
