import MobileLayout from "./MobileLayout/index";
import { useEffect, useRef } from "react";
import { getTailwindScreenSize } from "../Utils/General";
import WebLayout from "./WebLayout/index";
import Div100vh, { use100vh } from "react-div-100vh";
import { NotificationState } from "../State/Notifications";
import { useHookstate } from "@hookstate/core";
import { InternalCallApi } from "../Api/index";
import Message from "../Api/Actions/Message";
import {IsLoggedInState} from "../State/IsLoggedIn";

const withResponsiveLayout = (
  WrappedComponent,
  showBackButton = false,
  hideBottomNavigator = true
) => {
  return (props) => {
    const height = use100vh();
    const screenType = useRef(getTailwindScreenSize());
    const notificationState = useHookstate(NotificationState);
    const isLoggedIn = useHookstate(IsLoggedInState).get();


    useEffect(() => {
      (async () => {
        if (isLoggedIn) {
          try {
            const response = await (
                await InternalCallApi(
                    Message.getMessageCount({ Read: false, Details: true })
                )
            ).json();
            if (response.success) {
              console.log("setting state..............");
              notificationState.set(JSON.stringify(response.data));
            }
          } catch (e) {
            console.log(e);
          }
        }
      })();
    }, []);

    return (
      <Div100vh className={"overflow-hidden"} style={{ maxHeight: height }}>
        {(screenType.current == "md" || screenType.current == "sm") && (
          <MobileLayout
            hideBottomNavigator={hideBottomNavigator}
            showBackButton={showBackButton}
          >
            <WrappedComponent {...props} />
          </MobileLayout>
        )}
        {screenType.current != "md" && screenType.current != "sm" && (
          <WebLayout>
            <WrappedComponent {...props} />
          </WebLayout>
        )}
      </Div100vh>
    );
  };
};

export default withResponsiveLayout;
