import React, {useState} from 'react'
import HamrahButton from '../../Components/General/HamrahButton/index';
import Div100vh from 'react-div-100vh';

const AppError = () => {
  const [loading, setLoading] = useState(false);
  return <Div100vh
    className={'p-10 relative h-full mx-auto bg-white  flex-col flex-1 text-error font-bold text-center flex justify-center items-center'}>

    <div style={{backgroundImage: `url(${require('./../../assets/img/pback.png').default})`}}
         className='opacity-5 absolute left-0 top-0 h-full w-full'></div>

    <div className='max-w-desktop mx-auto text-center'>
      <img alt="خطا" src={require('./../../assets/img/error.svg').default} className={'w-1/3 p-5 max-w-36 mx-auto'}/>
      <div>متاسفانه خطای ناشناخته ای رخ داده است، لطفا دوباره برنامه را راه اندازی کنید</div>
      <div className={'flex flex-row w-full mt-6'}>
        <HamrahButton className={'mt-2 w-full md:max-w-48 md:mx-auto'}
                      loading={loading}
                      disabled={loading}
                      onClick={() => {
                        setLoading(true);
                        window.location.reload();
                      }}
        >راه اندازی مجدد</HamrahButton>
      </div>
    </div>
  </Div100vh>
}

export default AppError;