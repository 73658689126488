import HeaderBar from "../../Components/HeaderBar";

import { Link } from "react-router-dom";
import MemberShipModal from "../../Components/MemberShipModal";

import homeFilledIcon from "./../../assets/img/home-filled.svg";
import homeIcon from "./../../assets/img/home.svg";

import profileFilledIcon from "./../../assets/img/profile-filled.svg";
import profileIcon from "./../../assets/img/profile.svg";

import basketFilledIcon from "./../../assets/img/basket-filled.svg";
import basketIcon from "./../../assets/img/basket.svg";

import bell from "./../../assets/img/bell-bottom.svg";
import bellFilled from "./../../assets/img/bell-bottom - filled.svg";

import settingIconFilled from "./../../assets/img/settings-filled.svg";
import settingIcon from "./../../assets/img/setting.svg";

import messageFilledIcon from "./../../assets/img/message-filled.svg";
import messageIcon from "./../../assets/img/message.svg";

import upgradeIcon from "./../../assets/img/upload.svg";

import { ReactComponent as MyStockIcon } from "./../../assets/img/mystock.svg";
import { logEvent } from "../../Utils/FirebaseLog";
import React, { useState } from "react";
import { AppSectionTypeIds } from "../../Utils/Constants";
import { NotificationState } from "../../State/Notifications";
import { useHookstate } from "@hookstate/core";
import { motion } from "framer-motion";

const WebMenuItem = ({
  isActive = false,
  className = "",
  icon = null,
  label = "",
  disabled = false,
  notifications = `{"count":0}`,
}) => {
  const count =
    JSON.parse(notifications)?.count - JSON.parse(notifications)?.serviceCount;

  return (
    <div
      className={` flex flex-row items-center relative justify-between w-full ${className} ${
        disabled ? "opacity-30 pointer-events-none" : ""
      }`}
    >
      {isActive && (
        <motion.div
          initial={false}
          layoutId="active"
          className={"rounded-tl-xl rounded-bl-xl bg-brand w-2 h-6"}
        />
      )}
      <div
        className={`${
          isActive ? "pointer-events-none" : ""
        } hover:opacity-70 cursor-pointer transition-all active:opacity-70 flex flex-row flex-1 items-center justify-start ${
          isActive ? "mr-4" : "mr-6"
        }`}
      >
        <img alt="icon" src={icon} className={"w-6 icon"} />
        <div
          className={`text-wCaption font-bold ${
            isActive ? "text-brand" : "text-bodyText"
          } mr-3 text-right`}
        >
          {label}
        </div>
        {count > 0 && (
          <div
            className={
              "rounded-xl px-2 font-semibold flex justify-center items-center bg-error text-white dark:text-black text-xs mr-2 "
            }
          >
            <div className={"-mb-1 py-1"}>{count}</div>
          </div>
        )}
      </div>
    </div>
  );
};
const WebLayoutComponent = ({ children }) => {
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const notificationState = useHookstate(NotificationState).get();

  return (
    <div
      className={
        "h-screen bg-solid flex flex-col justify-between overflow-hidden"
      }
    >
      <HeaderBar />

      <div className={"w-full h-full flex flex-1 flex-row overflow-hidden"}>
        <div
          className={
            "flex bg-white flex-col border-l border-borderSolid w-1/5 items-center web-drawer relative pb-10 z-10"
          }
        >
          <div
            className={"absolute w-full h-full top-0 left-0 gradient-menu-back"}
          />
          <Link
            to="/mystock"
            className={`relative z-10 rounded-2xl w-2/3 min-h-14 mt-10 flex flex-row justify-center items-center ${
              window.location.hash.startsWith("#/mystock")
                ? "bg-gradient-to-r from-attention-light to-attention pointer-events-none"
                : "text-amber-400 border shadow-lg shadow-amber-400/40 border-amber-400 cursor-pointer hover:opacity-70"
            }`}
          >
            <div>
              <MyStockIcon
                className={`${
                  window.location.hash.startsWith("#/mystock")
                    ? "fill-white"
                    : "fill-amber-400"
                }`}
              />
            </div>
            <div className={"text-wSubtitle font-bold mx-2"}>سهام من</div>
          </Link>
          <div className={"mt-10 w-full relative z-10"}>
            <Link to="/">
              <WebMenuItem
                icon={window.location.hash == "#/" ? homeFilledIcon : homeIcon}
                label={"داشبورد"}
                isActive={window.location.hash == "#/"}
              />
            </Link>
            <Link to="/basket">
              <WebMenuItem
                icon={
                  window.location.hash.startsWith("#/basket")
                    ? basketFilledIcon
                    : basketIcon
                }
                label={"سبد سهام"}
                className={"mt-5"}
                isActive={window.location.hash == "#/basket"}
              />
            </Link>
            <Link to="/profile">
              <WebMenuItem
                icon={
                  window.location.hash.startsWith("#/profile")
                    ? profileFilledIcon
                    : profileIcon
                }
                label={"پروفایل"}
                className={"mt-5"}
                isActive={window.location.hash == "#/profile"}
              />
            </Link>
            <Link to="/bell">
              <WebMenuItem
                icon={
                  window.location.hash.startsWith("#/bell") ? bellFilled : bell
                }
                label={"گوش به زنگ"}
                className={"mt-5"}
                isActive={window.location.hash == "#/bell"}
              />
            </Link>
            <Link to="/conversations">
              <WebMenuItem
                notifications={notificationState}
                icon={
                  window.location.hash.startsWith("#/conversation")
                    ? messageFilledIcon
                    : messageIcon
                }
                isActive={window.location.hash.startsWith("#/conversation")}
                label={"صندوق پیام"}
                className={"mt-5"}
              />
            </Link>
            <div
              onClick={() => {
                setShowMembershipModal(true);
                logEvent("PWA_accountUpgrade");
              }}
            >
              <WebMenuItem
                icon={upgradeIcon}
                label={"ارتقا حساب"}
                className={"mt-5"}
              />
            </div>
            <Link to="/settings">
              <WebMenuItem
                icon={
                  window.location.hash.startsWith("#/settings")
                    ? settingIconFilled
                    : settingIcon
                }
                label={"تنظیمات"}
                isActive={window.location.hash.startsWith("#/settings")}
                className={"mt-5"}
              />
            </Link>
          </div>
        </div>

        <div
          className={"flex-1 flex flex-col justify-between w-4/5 m-auto my-0"}
        >
          {children}
        </div>
      </div>
      {showMembershipModal && (
        <MemberShipModal
          closemodal={() => setShowMembershipModal(false)}
          permissionType={AppSectionTypeIds.BASKET}
        />
      )}
    </div>
  );
};

export default React.memo(WebLayoutComponent);
