import React from 'react'

import {shadeColor} from "../../../Utils/General"
import Spinner from 'react-spinner-material';

const baseColors=require("../../../Utils/Colors").baseColors

const Div=({children,...rest})=><div {...rest}><span>{children}</span></div>
const HamrahButtonComponent = ({ as='div',justLoading=false,noOpacityOnDisable = false,contentClassName='', onClick = () => { }, variant = 'filledPrimary', progress = 0, small = false, disabled = false, loading = false, children, className = '',rightIcon=null,leftIcon=null ,...rest}) => {
  const _class = []
  const variantColors = {
    'filledPrimary': baseColors.Brand.Default,
    'hidden': '#ffffff',
    'cancel': baseColors.Accent.Negative,
    'error': baseColors.Error.Light,
    'attention': baseColors.Attention.Default,
    'success':baseColors.Accept.Light
  }
  const variantStyles = {
    'filledPrimary': 'bg-brand dark:bg-accent-dark dark:text-title text-white',
    'hidden': 'bg-transparent text-brand',
    'cancel': 'bg-accent-negative dark:bg-actions-dark text-title',
    'error': 'bg-error dark:text-title text-white',
    'attention': 'bg-attention text-white',
    'success': 'bg-accept dark:text-title text-white',
  }

  const loadingColors = {
    'filledPrimary': '#ffffff',
    'hidden': baseColors.Brand.Default,
    'cancel': baseColors.Brand.Default,
    'error': '#ffffff',
    'attention': '#ffffff',
    'success':'#ffffff'
  }
  if (disabled || loading) {
    if (!noOpacityOnDisable)
      _class.push('opacity-50')
    _class.push('pointer-events-none')
  }

  if (loading || disabled) _class.push('cursor-default')
  if (!loading && !disabled) _class.push('cursor-pointer')

  if (small) _class.push('h-10 text-buttonSmall rounded-xl')
  else _class.push('h-12 text-buttonSmall rounded-2xl')

  _class.push(variantStyles[variant])
  const WrapperComponent=as=='div'?Div:as
  
  return <WrapperComponent style={{...(disabled || loading)?{filter:'grayscale(1)'}:{}}}   className={`select-none ${className}`} onClick={(disabled || loading)?undefined:onClick} {...rest}>
    <div  className={`active:opacity-70 px-2 transition-all overflow-hidden font-bold w-full flex justify-center items-center relative ${_class.join(' ')}`}>
      {progress > 0 && <div className={`h-full opacity-70 absolute left-0 transition-all`} style={{
  width: `${progress}%`,
  backgroundColor: shadeColor(variantColors[variant], 60)
}}/>}
      <div className={`relative z-10 flex flex-row justify-center items-center ${contentClassName}`}>
        {loading==true && <div style={{ height: 15 }} className={'mx-2'}><Spinner radius={15} color={loadingColors[variant]} stroke={2} visible={true} /></div>}
        {rightIcon}
        {(justLoading==true && loading==true)?<span></span>:<span>{children}</span>}
        {leftIcon}
      </div>
    </div>
  </WrapperComponent>
}

export default HamrahButtonComponent;