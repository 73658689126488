
import CryptoJS from 'crypto-js';
import { API_KEY } from '../Api/Constants';

   
const MIN_KEY_LENGTH=80
const generateKey=()=>
{
 
    try{
        return ('4t7w!z%C*F)J@NcR'+API_KEY+localStorage.getItem('token'));
    }
    catch(e)
    {
        return ''
    }
    
}

const SecureStorage=()=>({
    
    setItem:(key,value)=>{
        localStorage.setItem(key,value)
        return
        const KEY=generateKey();
        
        if (KEY.length<MIN_KEY_LENGTH)
        { 
            localStorage.setItem(key,value)
        }
        else  
        {
            const encValue=Encrypt(value,KEY)
            localStorage.setItem(key,encValue);
        }
    },
    getItem:(key)=>{
        return localStorage.getItem(key)
        
        try
        {
            const KEY=generateKey();

            if (KEY.length<MIN_KEY_LENGTH)
            {
                return localStorage.getItem(key)
            }
            else
            {
                
                const value=localStorage.getItem(key);
                var decryptedData = Decrypt(value,KEY)
                
                if (decryptedData == '' || decryptedData == null)
                    return localStorage.getItem(key)
                    
                return decryptedData;
            }
        }
        catch(e)
        {
            console.log('getItem error',e)
            return localStorage.getItem(key)
        }
        
    },
    removeItem:(key)=>{
        localStorage.removeItem(key)
    }
})

function Encrypt(word, key) {
    let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
    return encData
}
export function Decrypt(word, key) {
    let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
    let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
    return JSON.parse(bytes)
}


export default SecureStorage;