import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles.css";

import moment from "moment-jalaali";
import ErrorBoundary from "./Components/ErrorBoundary";
import { InternalCallApi, setDefaults } from "./Api";
import { EventActions } from "./Api/Actions/Event";
import {
  browserName,
  browserVersion,
  isIOS,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";
import { appendVersionSymbol, isHybrid } from "./Utils/General";
import { deepSetState, UserState } from "./State";
import { IsLoggedInState } from "./State/IsLoggedIn";

const CircularBuffer = require("mnemonist/circular-buffer");
const pjson = require("./../package.json");

export const cq = new CircularBuffer(Array, 5);

setDefaults();
if (
  process.env.NODE_ENV !== "development" &&
  localStorage.getItem("FORCE_SHOW_LOGS") == null
)
  console.log = () => {};

IsLoggedInState.set(false);

window.addEventListener("unhandledrejection", (event) => {
  event.preventDefault();
  event.stopPropagation();

  try {
    InternalCallApi(
      EventActions.Add({
        OS:
          mobileVendor +
          " " +
          mobileModel +
          "  " +
          osName +
          " " +
          osVersion +
          "-" +
          browserName +
          "-" +
          browserVersion,
        StackTrace: event?.reason?.message + "======" + event?.reason?.stack,
        Version: appendVersionSymbol(pjson.version, true),
        Type: isHybrid() ? "Hybrid-Error" : "PWA-Error",
        Message: isHybrid() ? "Hybrid-Error" : "PWA-Error",
      }),
      true
    );
  } catch (err) {}
});

if (isHybrid()) {
  try {
    const _oldStorage = window?.Android?.getOldLogin();

    if (_oldStorage != "" && _oldStorage != null) {
      const oldStorage = JSON.parse(_oldStorage);
      oldStorage.user = JSON.parse(oldStorage.user);

      deepSetState(UserState, { ...oldStorage.user }, true, "UserState");
    }
  } catch (e) {}
}

moment.loadPersian();
createRoot(document.getElementById("root")).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

if (!isHybrid() && !isIOS) serviceWorkerRegistration.register();
