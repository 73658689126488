import {get, post} from "./Utils"
import {Authorization, User} from '../Constants'
import {getAuthorization} from ".."
import BrowserInfo from '@smartbear/browser-info'
import {toEnglishDigit} from "../../Utils/General";
import {isHybrid} from '../../Utils/General';
import {mobileVendor, mobileModel} from 'react-device-detect';
import {isMobile} from 'react-device-detect';

var pjson = require('../../../package.json');


export const loginUser = (data) => {
  BrowserInfo.detect();

  return ({
    url: Authorization.login, ...post,
    data: {
      DeviceType: isHybrid()?'Hybrid':'Browser',
      DeviceName: `${isMobile ? `${mobileVendor} ${mobileModel} ` : ''}${BrowserInfo.os}${isHybrid() ? '' : '-' + BrowserInfo.name}${isHybrid() ? '' : ''}`,
      OsVersion: BrowserInfo.os,
      VersionName: pjson.version,
      VersionCode: parseInt(pjson.version.replace(/\./g, ''))
    },
    headers: {Authorization: `Basic ${btoa(unescape(encodeURIComponent(toEnglishDigit(`${data.username}:${data.password}`))))}`}
  })
}

export const getUserProfile = () => {
  return ({url: User.me, ...post, headers: {Authorization: `Bearer ${getAuthorization()}`}})
}

export const requestOTP = (MobileNumber,CaptchaCode,CaptchaText) => {
  return ({url: Authorization.otp, ...post, data: {MobileNumber,CaptchaCode,CaptchaText}})
}

export const requestCaptcha  = () => {
  return ({url: Authorization.captcha, ...post})
}

export const verifyOTPCode = (MobileNumber, OTPCode) => {
  BrowserInfo.detect();
  return ({
    url: Authorization.verifyOtp, ...post,
    data: {
      MobileNumber: MobileNumber,
      VerificationCode: OTPCode,
      DeviceType: isHybrid()?'Hybrid':'Browser',
      DeviceName: `${isMobile ? `${mobileVendor} ${mobileModel}` : ''} ${BrowserInfo.os}${isHybrid() ? '' : '-' + BrowserInfo.name}${isHybrid() ? '' : '-PWA'}`,
      OsVersion: BrowserInfo.os,
      VersionName: pjson.version,
      VersionCode: parseInt(pjson.version.replace(/\./g, ''))
    }
  })
}

export const changePassword = (NewPassword) => {
  return ({
    url: Authorization.changePassword, ...post,
    data: {NewPassword},
    headers: {Authorization: `Bearer ${getAuthorization()}`}
  })
}

export const getTokens = () => {
  return ({url: Authorization.tokens, ...post, headers: {Authorization: `Bearer ${getAuthorization()}`}})
}

export const terminateToken = (model) => ({
  url: Authorization.terminate, ...post,
  data: {...model},
  headers: {Authorization: `Bearer ${getAuthorization()}`}
})
export const Register = (password, email) => ({
  url: Authorization.register, ...post,
  data: {Password: password, ...(email && {Email: email})},
  headers: {Authorization: `Bearer ${getAuthorization()}`}
});


export const setFcmToken = (model) => ({
  url: Authorization.setFcmToken, ...post,
  data: model,
  headers: {Authorization: `Bearer ${getAuthorization()}`}
})