import ModalWrapper from '../../Components/General/ModalWrapper';
import Div100vh from 'react-div-100vh';
import {useState, useEffect} from 'react';
import {getPWADisplayMode} from '../../Utils/General';
import HamrahButton from '../../Components/General/HamrahButton/index';
import {isIOS} from 'react-device-detect'

const IosAddToHomeScreen = () => {
    const [showPWAInstallDialog, setShowPWAInstallDialog] = useState(false)

    const onUnderstandClick = () => {

        localStorage.setItem('PWA_OK', 'OK');
        setTimeout(() => {
            setShowPWAInstallDialog(false)
        }, 100)
    }

    useEffect(() => {

        if (localStorage.getItem('PWA_OK') != 'OK')
            if (getPWADisplayMode() != 'standalone' && isIOS)
                setShowPWAInstallDialog(true)
            else setShowPWAInstallDialog(false)
    }, [])


    const logo =  document.documentElement.classList.contains('dark') ? require('./../../assets/img/logo-light.svg').default : require('./../../assets/img/logo.svg').default

    return <ModalWrapper isOpen={showPWAInstallDialog} fullScreen modalCss='!p-0'>
        <Div100vh className='bg-white flex justify-center items-center flex-col p-10 relative'>
            <div style={{ backgroundImage: `url(${require('./../../assets/img/pback.png').default})` }} className='opacity-5 absolute left-0 top-0 h-full w-full'></div>
            <div className='flex my-5 flex-1 flex-col items-center justify-center'>
                <div className='flex'>
                    <img src={logo} className='w-40'/>
                    <img src={require('./../../assets/img/pwa-logo.svg').default} className='w-10 mr-5 icon'/>
                </div>
                <div className='mt-4 font-semibold text-brand text-header text-center'>
                    نصب وب اپلیکیشن همراه بورس
                </div>
            </div>

            <div className='text-center dark:text-title my-5'>
                با این کار همیشه میتوانید از خدمات همراه بورس به آسانی استفاده کنید
            </div>

            <div className='flex items-center justify-between mb-5 w-full'>
                <div className='flex-1 dark:text-title text-right'>۱. دکمه share را از نوار پایین لمس کنید</div>
                <div><img alt="ios-share" src={require('./../../assets/img/ios-share.svg').default}
                          className='w-5 invertIcon'/></div>
            </div>
            <div className='flex items-center justify-between mb-5 w-full'>
                <div className='flex-1 dark:text-title text-right'>۲. گزینه Add to Home Screen را لمس کنید</div>
                <div><img alt="ios-add" src={require('./../../assets/img/ios-add.svg').default}
                          className='w-5 invertIcon'/></div>
            </div>

            <div className='flex items-center justify-between mb-5 w-full'>
                <div className='flex-1 dark:text-title text-right'>۳. در قسمت بالا روی Add بزنید</div>
                <div></div>
            </div>

            <HamrahButton onClick={onUnderstandClick} className='w-full mt-5'>متوجه شدم</HamrahButton>

        </Div100vh>
    </ModalWrapper>
}

export default IosAddToHomeScreen;