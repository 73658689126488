import { getAuthorization } from ".."
import { Init } from "../Constants"
import { post } from "./Utils"

export const GetAllProvinces = () => {
    return ({ url: Init.getAllProvinces , ...post, data: { WithCities: true }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
}

export const getRemoteConfig=(model)=>{
    return ({url:Init.remoteConfig,...post,data:{...model},headers:{Authorization:`Bearer ${getAuthorization()}`}})
}

export const getPlans=(model)=>{
    return ({url:Init.plans,...post,data:{...model},headers:{Authorization:`Bearer ${getAuthorization()}`}})
}

export const getServerTime=()=>{
    return ({url:Init.server})
}
