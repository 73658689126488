import { getAuthorization } from ".."
import { Stocks, User } from "../Constants"
import {  post } from "./Utils"


export const getUserPersonalInfo = (CompanyId) => ({ url: Stocks.mystock, ...post, data: { CompanyId }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const getUserBankRequestStatus = (model) => ({ url: Stocks.GetBankAccountChangeRequests, ...post, data: { ...model }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const updateUserData = (model) => ({ url: Stocks.UpdateUserData, ...post, data: { ...model }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const getUserPicture = (model) => ({ url: '/User/Me', ...post, data: { ...model }, headers: { Authorization: `Bearer ${getAuthorization()}` } })

export const UploadNationalCard = (NationalIdentityCardFrontPictureId, NationalIdentityCardBackPictureId,UserStockId) =>
({
    url: User.updateUserProfile, ...post, data: {
        NationalIdentityCardFrontPictureId: NationalIdentityCardFrontPictureId,
        NationalIdentityCardBackPictureId: NationalIdentityCardBackPictureId,
        UserStockId:`${UserStockId}`
    }, headers: { Authorization: `Bearer ${getAuthorization()}` }
})

export const uploadShenasnameh = (BirthCertificatePageOnePictureId, BirthCertificatePageTwoPictureId,UserStockId) =>
({
    url: User.updateUserProfile, ...post, data: {
        BirthCertificatePageOnePictureId: BirthCertificatePageOnePictureId,
        BirthCertificatePageTwoPictureId: BirthCertificatePageTwoPictureId,
        UserStockId:UserStockId
    }, headers: { Authorization: `Bearer ${getAuthorization()}` }
})

export const setNationalId = (NationalIdentityNumber) => ({ url: User.setNationalId, ...post, data: { NationalIdentityNumber: NationalIdentityNumber }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const updateUserProfile=(model)=>({ url: User.updateUserProfile, ...post, data: { ...model }, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const startFreeTime=()=>({ url: User.startFreeTime, ...post, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const bePremium=(model)=>({ url: User.bePremium, data:{...model},...post, headers: { Authorization: `Bearer ${getAuthorization()}` } })
export const GetDetected = () => ({ url: User.detect, ...post, data: {}, headers: { Authorization: `Bearer ${getAuthorization()}` } });

export const UpadateUserProfile=(firstName , lastName , rulesAccepted)=>({ url: User.updateUserProfile, ...post,
     data: {FirstName:firstName , LastName: lastName , RulesAccepted:rulesAccepted}, headers: { Authorization: `Bearer ${getAuthorization()}` } });

export const validateCafebazaar=(model)=>({url:User.cafebazaarValidate,...post,data:{...model}, headers: { Authorization: `Bearer ${getAuthorization()}` }})
export const validateMyket=(model)=>({url:User.myketValidate,...post,data:{...model}, headers: { Authorization: `Bearer ${getAuthorization()}` }})
