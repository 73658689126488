// import { API_KEY } from "./MysteriousApiKey"


const isHybrid = navigator.userAgent.indexOf('hybrid-android') >= 0
export const API_KEY = isHybrid ? '3w9ZLt7VjUDenX2uYQtSRMGMufyxqpBgvm8REQCNASDysvCgVUKDxg2CnqYnpbvuntbC8qfj7vtqQUgBECQMvGeuG4FFQZZ44MwM2pVKHYY9JRnYjNCq7tHD8eVNQLtK' : '66a03e8e-fbc5-4b10-bdde-24c52488eb8bd6479050b'
// export const BASE_URL = 'https://api.hbbs.ir'
export let BASE_URL = (window.location.host.startsWith('app.hbbs.ir') || window.location.host.startsWith('saham.bidc.ir')) ? 'https://api.hbbs.ir' :
  window.location.host.includes('demo') ? 'http://api.demo.hamrahbours.ir' : 'https://st2api.hbbs.ir'

export const setBaseUrl = (url) => {

  if (url == null || url == '') {
    url = (window.location.host.startsWith('app.hbbs.ir') || window.location.host.startsWith('saham.bidc.ir')) ? 'https://api.hbbs.ir' :
      window.location.host.includes('demo') ? 'http://api.demo.hamrahbours.ir' : 'https://st2api.hbbs.ir'
  }
  else localStorage.setItem('BURL', url)
  BASE_URL = url

}

export const FETCH_DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  "ApiKey": API_KEY,
  "Accept": "application/json"

}
export const Ticket = {
  All: '/ContactUs/All',
  Add: '/ContactUs/Add'
}
export const FETCH_DEFAULT_FORM_HEADERS = {
  "ApiKey": API_KEY,
  "Accept": "application/json"

}
export const Authorization = {
  login: '/authentication/login',
  otp: '/authentication/SendCode',
  verifyOtp: '/authentication/VerifyCode',
  changePassword: '/authentication/ChangePassword',
  tokens: '/authentication/Tokens',
  terminate: '/authentication/Tokens/Terminate',
  register: '/authentication/register',
  setFcmToken: '/user/SetFirebaseMessagingServiceToken',
  captcha: '/authentication/captcha',
}

export const User = {
  me: '/User/Me',
  updateUserProfile: '/user/UpdateUserProfile',
  setNationalId: '/User/SetNationalIdentityNumber',
  startFreeTime: '/user/StartFreeTime',
  detect: '/User/Detect',
  bePremium: '/User/BePremium',
  cafebazaarValidate: '/user/BePremium/cafebazaar/validate',
  myketValidate: '/user/BePremium/myket/validate'
}

export const Statistic = {
  all: '/Statistic/All'
}
export const filteredBankForVaMaaden = [
  {
    id: 1,
    name: 'ملت'
  },
  {
    id: 2,
    name: 'ملی'
  },
  {
    id: 3,
    name: 'تجارت'
  },
  {
    id: 4,
    name: 'سپه'
  },
  {
    id: 5,
    name: 'صادرات'
  },
  {
    id: 6,
    name: 'اقتصاد نوین'
  },
  {
    id: 7,
    name: 'پارسیان'
  }
];

export const Market = {
  TopBuyingAndSellingDemands: '/Market/TopBuyingAndSellingDemands',
  LastActivity: '/Market/LastActivity',
  GetPrice : '/Market/GetPrice',
  GetBoard : '/Market/GetBoard'
}
export const Event = {
  Add: '/Event/Add',

}
export const Company = {
  all: '/Company/All',
  details: '/company/Details',
  epsDps: '/company/GetEpsDps',
  trades: '/Company/Trades',
  codal: '/company/Codal',
  add: '/Company/Add',
  remove: '/Company/Remove',
  categories: '/Company/Categories',
  holding: '/Company/GetHoldingCompanies',
}

export const Survey = {
  all: '/Survey/All',
  answer: '/Survey/Answer'
}

export const DataStorage = {
  add: '/DataStorage/Add',
  get: '/DataStorage/Get'
}

export const Stocks = {
  mystock: '/Stock/MyStock',
  GetBankAccountChangeRequests: '/Stock/GetBankAccountChangeRequests',
  UpdateUserData: '/Stock/UpdateUserData',
  GetCertificate: '/Stock/StockRights/GetCertificate',
  DocumentDetails: '/Stock/Document/Details',
  DocumentUpload: '/Stock/Document/Upload',
  BankChange: '/Stock/UpdateUserData',
  AddressChange: '/Stock/UpdateUserData',
  SendPaymentDetailsAfterExpire: '/Stock/StockRights/SendPaymentDetailsAfterExpire',
}


export const StockRight = {
  inquery: '/StockRight/Inquiry',
  takwBalance: '/StockRight/TakeBalance',
  regret: '/StockRight/Regrette',
  sendPaymentDetails: '/StockRight/SendPaymentDetails',
  takeBalance: '/StockRight/TakeBalance',
  onlinePayment: '/StockRight/OnlinePayment',
  GiveUpSendCode: '/StockRight/GiveUp/SendCode',
  GiveUp: '/StockRight/GiveUp',
  RegretSendCode: '/StockRight/SendRegretteVerificationCode'
}

export const Sejam = {
  isSejamai: '/Sejam/IsSejami',
  confirmSejamProfiling: '/Sejam/ConfirmProfiling',
  ShowSejamiProfile: '/Sejam/ShowSejamiProfile',
  updateUserData: '/Sejam/UpdateUserData',
  getProvinces: '/Sejam/GetProvinces',
  getCities: '/Sejam/GetCities'
}

export const News = {
  all: '/news/all'
}


export const Message = {
  All: '/Message/User/All',
  Add: '/Message/User/Add',
  Count:'/Message/User/Count',
  ReadAll:'/Message/User/Read'
}

export const Faq = {
  All: '/Faq/All'
}

export const Stock = {
  getBankAccountChangeRequests: '/Stock/GetBankAccountChangeRequests',
  getAddressChangeRequests: '/Stock/GetAddressChangeRequests',
  getAllCondition: '/Stock/AllCondition',
  removeCondition: '/Stock/RemoveCondition',
  addCondition: '/Stock/AddCondition',
  addStocksRequest: '/Stock/AddStocksRequest',
  stocksRequests: '/Stock/StocksRequests',
  addLawyerRequest: '/Stock/AddLawyerRequest',
  lawyerRequests: '/Stock/LaywerRequests',
  AddProfileChangeRequest: '/Stock/AddProfileChangeRequest',
}

export const Init = {
  getAllProvinces: '/Init/Provinces',
  remoteConfig: '/Init/GetRemoteConfigValue',
  plans: '/Init/Plans',
  server: '/'
}

export const BasketConstants = {
  getAllBaskets: '/Basket/All',
  edit: '/Basket/Edit',
  remove: '/Basket/Remove',
  add: '/Basket/Add',
  removeCompany: '/Basket/Company/Remove',
  addCompany: '/Basket/Company/Add',
  editCompany: '/Basket/Company/Edit'
}

export const LearningConstants = {
  AllSubjects: '/Learning/Subjects',
  AllLearning: '/Learning/All',
  Like: '/Learning/Likes/Add',
  Remove: '/Learning/Likes/Remove',
  Details: '/Learning/Details'
}

export const Ehraz = {
  captchaUrl: (captchaKey) => `https://dev-huami.hamisystem.ir/api/v1/captcha/image?captcha-key=${captchaKey}`,
  getToken: '/Sejam/GetToken',
  pay: '/Sejam/Payment',
  getPayment: '/Sejam/GetPayment',
  finalize: '/Sejam/finalize',
  requestOtp: 'https://dev-huami.hamisystem.ir/api/v1/sejam/otp',
  otpCheck: 'https://dev-huami.hamisystem.ir/api/v1/sejam/otp/check',
  details: 'https://dev-huami.hamisystem.ir/api/v1/people/detail/',
  setMeliSerial: 'https://dev-huami.hamisystem.ir/api/v1/sabt/pic',
  uploadDocument: 'https://dev-huami.hamisystem.ir/api/v1/attachments/upload',
  selectChallenge: 'https://dev-huami.hamisystem.ir/api/v1/challenges/select',
  documentUrl: 'https://dev-huami.hamisystem.ir/api/v1/contents/'
}

export const MercantileMarket = {
  mainGroup: '/MercantileMarket/GetMainGroup',
  catGroups: '/MercantileMarket/GetCatGroups',
  subCatGroups: '/MercantileMarket/GetSubCatGroups',
  producers: '/MercantileMarket/GetProducers',
  physicalsStatistic: '/MercantileMarket/PhysicalsStatistic',
  exportsStatistic: '/MercantileMarket/ExportsStatistic',
  premiumsStatistic: '/MercantileMarket/PremiumsStatistic',
}