import React from "react";

const BottomNavigatorMenuItemComponent = ({
  label = "",
  icon = null,
  onClick = () => {},
  disabled = false,
  commingSoon = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${
        disabled ? "pointer-events-none" : ""
      } relative flex items-center justify-center flex-col -mb-4 active:opacity-70 transition-all duration-75`}
    >
      <img
        alt="icon"
        src={icon}
        className={`${disabled ? "pointer-events-none" : ""} w-5 icon`}
      />
      <div
        className={`${
          disabled ? "pointer-events-none" : ""
        } text-wCaptionSmall font-bold text-bodyText mt-2`}
      >
        {label}
      </div>
      {commingSoon && (
        <div
          className={
            "bg-white w-16 font-semibold  px-2 py-0 text-caption text-bodyText absolute transform top-3 right-0"
          }
        >
          به زودی...
        </div>
      )}
    </div>
  );
};

export default React.memo(BottomNavigatorMenuItemComponent);