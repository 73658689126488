const OldVersionModal = () => {
  return (
    <div className="flex flex-col">
      <img
        alt="alert"
        src={require("../../assets/img/alert.svg").default}
        className={"h-24"}
      />
      <p className="text-center text-wBody font-semibold text-error leading-6">کاربر گرامی متاسفانه برنامه ی شما برای بروز رسانی
        با مشکل مواجه شده است.لطفا
        برای استفاده از برنامه با پشتیبانی تماس بگیرید
      </p>
    </div>
  );
};

export default OldVersionModal;