export const MIN_BALANCE = 1;

export const FIRST_OTP_REQUEST_ATTEMPT = 300;
export const DisplayType = Object.freeze({ Grid: "Grid", List: "List" });
export const MAX_CHAT_FILE_SIZE = 10;
export const MAX_PAPER_FILE_SIZE = 20;
export const REQUEST_TIME_EXPIRY = 3600;
export const AppSectionTypeIds = Object.freeze({
  BASKET: 1,
  COMPARE: 2,
  NOTIF_ON_SYMBOL: 3,
  NOTIF_ON_BASKET: 4,
  NOTIF_ON_CODAL: 5,
  CALENDAR: 6,
});

export const AppSectionPermissionTypeValue = Object.freeze({
  1: [4, 0, 1, 2, 3],
  2: [4, 0, 1, 2, 3],
  3: [4, 0, 1, 2, 3],
  4: [4, 0, 1, 2, 3],
  5: [4, 0, 1, 2],
  6: [4, 0, 1],
});

export const SliderPlanIndex = Object.freeze({
  1: 0, //gold
  2: 1, //sliver
  3: 2, // bronze
});

export const AppSectionTypeIdNames = Object.freeze({
  1: "BASKET",
  2: "COMPARE",
  3: "NOTIF_ON_SYMBOL",
  4: "NOTIF_ON_BASKET",
  5: "NOTIF_ON_CODAL",
  6: "CALENDAR",
});
export const AppSectionPermissionTypes = Object.freeze({
  1: `<div>کاربر گرامی، برای ساخت سبد سهام می بایست یکی از اشتراک های برنزی، نقره ای یا طلایی برای شما فعال باشد. <br/> همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.</div>`,
  2: `<div>کاربر گرامی، برای مقایسه سبد سهام می بایست یکی از اشتراک های برنزی، نقره ای یا طلایی برای شما فعال باشد. <br/> همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.</div>`,
  3: "<div>کاربر گرامی، برای تنظیم گوش به زنگ می بایست یکی از اشتراک های برنزی، نقره ای یا طلایی برای شما فعال باشد. همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.</div>",
  4: "<div>کاربر گرامی، برای تنظیم گوش به زنگ روی سبد سهام می بایست یکی از اشتراک های برنزی، نقره ای یا طلایی برای شما فعال باشد. همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.</div>",
  5: `کاربر گرامی، برای تنظیم گوش به زنگ روی آگهی های کدال، می بایست یکی ا ز اشتراک های نقره ای یا طلایی برای شما فعال باشد. همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.`,
  6: "<div>کاربر گرامی، برای مشاهده ی اطلاعات / تنظیم گوش به زنگ تقویم می بایست اشتراک طلایی برای شما فعال باشد. همچنین شما می توانید به مدت 3 روز از اشتراک رایگان استفاده کنید.</div>",
});

export const SymbolStatus = [
  {
    id: 1,
    value: "I",
    label: "ممنوع",
    name: "ممنوع",
    color: "text-error",
  },
  {
    id: 2,
    value: "A",
    label: "مجاز",
    name: "مجاز",
    color: "text-accept",
  },
  {
    id: 3,
    value: "AG",
    label: "مجاز-مسدود",
    name: "مجاز-مسدود",
    color: "text-brand",
  },
  {
    id: 4,
    value: "AS",
    label: "مجاز-متوقف",
    name: "مجاز-متوقف",
    color: "text-brand",
  },
  {
    id: 5,
    value: "AR",
    label: "مجاز-محفوظ",
    name: "مجاز-محفوظ",
    color: "text-accept",
  },
  {
    id: 6,
    value: "IG",
    label: "ممنوع-مسدود",
    name: "ممنوع-مسدود",
    color: "text-error",
  },
  {
    id: 7,
    value: "IS",
    label: "ممنوع-متوقف",
    name: "ممنوع-متوقف",
    color: "text-error",
  },
  {
    id: 8,
    value: "IR",
    label: "ممنوع-محفوظ",
    name: "ممنوع-محفوظ",
    color: "text-error",
  },
];

export function getFileTypeName(typeId) {
  let o = Object.freeze({
    0: "text",
    1: "audio",
    2: "video",
    3: "image",
    4: "file",
    5: "zip",
  });

  return o[typeId];
}

export function getFileTypeNameFa(typeId) {
  let o = Object.freeze({
    0: "متن",
    1: "صوت",
    2: "ویدئو",
    3: "تصویر",
    4: "فایل/سند",
    5: "فایل فشرده",
  });

  return o[typeId];
}

export function getRequestStatusTitle(statusId) {
  // let o = Object.freeze({
  //   '-3': { value: '', className: '' },
  //   '-2': { value: 'در حال بارگذاری...', className: 'text-details' },
  //   '-1': { value: 'عدم تایید', className: 'text-error' },
  //   0: { value: 'در حال بررسی', className: 'text-attention' },
  //   1: { value: 'تایید شده', className: 'text-accept' }
  // })

  // return o[statusId]

  switch (statusId) {
    case 0:
      return { value: "در حال بررسی", className: "text-attention" };
    case 1:
      return { value: "تایید شده", className: "text-accept" };
    case -1:
      return { value: "عدم تایید", className: "text-error" };
    case 2:
      return { value: "در حال استعلام", className: "text-brand" };
    default:
      return { value: "", className: "" };
  }
}

export const EhrazStepsPart1 = [
  "شروع",
  "ورود اطلاعات",
  "کدتایید",
  "قوانین و مقررات",
];
export const EhrazStepsPart2 = [
  "سریال کارت ملی",
  " فرستادن عکس",
  "تصویر امضا",
  "چالش",
];

export const EhrazStepsAll = [
  "شروع",
  "ورود اطلاعات",
  "کدتایید",
  "قوانین و مقررات",
  "سریال کارت ملی",
  " فرستادن عکس",
  "تصویر امضا",
  "چالش",
];
export const ACCEPTED_FILE_TYPE_FOR_CHAT =
  "audio/mpeg,audio/mp4,audio/ogg,audio/wav,audio/aac,audio/x-ms-wma,video/mp4,video/webm,video/x-m4v,video/quicktime,video/x-ms-wmv,video/mpeg,video/x-msvideo,image/jpeg,image/jpg,image/png,image/bmp,application/pdf,application/gzip,application/zip,application/x-7z-compressed,application/vnd.rar";

export const contactInfo = {
  tel: "021-88616116",
  mobile: "09100018628",
  email: "support@hamrahbours.ir",
  instagram: "https://instagram.com/_u/hamrahbours.ir",
  instagramLabel: "instagram.com/hamrahbours.ir",
};
