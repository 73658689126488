import BottomNavigator from './../../Components/BottomNavigator/index';
import HeaderBar from "../../Components/HeaderBar";
import {useHistory} from 'react-router-dom';
import Div100vh from "react-div-100vh";
import React from "react";

const MobileLayoutComponent = ({children, showBackButton = false, hideBottomNavigator = false}) => {
  const history = useHistory()
  const navigatorClick = (item) => {

    if (item == 'mystock') {
      history.push('/mystock')
    } else if (item == 'profile') {
      history.push('/profile')
    } else if (item == 'home') {
      history.push('/')
    } else if (item == 'basket') {
      history.push('/basket')
    } else if (item == 'bell') {
      history.push('/bell')
    }
  }
  return <Div100vh className={'bg-solid flex flex-col justify-between'}>

    <HeaderBar showBackButton={showBackButton} onBackClick={() => window.history.back()}/>

    {children}
    {!hideBottomNavigator &&
        <div className={'absolute left-0 w-full z-40 bottom-0'}><BottomNavigator  onClickItem={navigatorClick}/></div>}
  </Div100vh>
}


export default React.memo(MobileLayoutComponent);