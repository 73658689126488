import "firebase/compat/analytics";
import firebase from 'firebase/compat/app';
import { isHybrid } from './General';

export const logEvent = (name, data = null) => {
    try {
        if (process.env.NODE_ENV == 'development') return;

        
        if (isHybrid())
        {
            window.Android.onLogEvent(name.replace('PWA','HYBRID'))
            return
        }
        const analytics = firebase.analytics();

        if (data) {
            console.log('logEvent', name, data)
            analytics.logEvent(name, data);
        }
        else {
            console.log('logEvent', name)
            analytics.logEvent(name);
        }
    }
    catch (e) {
        console.log(e)
    }

}