import Spinner from "react-spinner-material";
import withResponsiveLayout from "../../Layouts/ResponsiveLayout";

const ThemedSuspense = () => {
  return (
    <div
      className={"flex flex-col justify-center items-center mx-auto h-screen"}
    >
      <div>
        <Spinner radius={20} stroke={2} color="var(--Brand-Default)" />
      </div>
      <div className={"mt-3 text-title"}>لطفا صبر کنید...</div>
    </div>
  );
};

export default withResponsiveLayout(ThemedSuspense, true);
