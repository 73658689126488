import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { use100vh } from "react-div-100vh";
import HeaderBar from "../HeaderBar";

const ModalWrapper = ({
  isOpen,
  fullWidth = false,
  closemodal = () => {},
  children,
  title = "",
  modalCss = "",
  showHeaderBar = false,
  autoWidth = false,
  fullScreen = false,
  style = {},
}) => {
  const height = use100vh();
  const _closemodal = () => {
    closemodal();
  };

  const focusButton = useRef(null);
  return (
    <Transition appear show={isOpen} as={"div"} className="hidden">
      <button
        ref={focusButton}
        tabIndex={0}
        className={"opacity-0 absolute bottom-0"}
      />
      <Dialog
        initialFocus={focusButton}
        as={"div"}
        style={{ maxHeight: height, height: height - 0.5, zIndex: 1000000 }}
        className={`fixed top-0 left-0 ${
          fullScreen
            ? `w-screen h-full ${fullWidth ? "w-full" : "md:w-4/5"}`
            : "w-screen"
        } flex items-center justify-center overflow-auto backdrop-blur-sm`}
        onClose={() => {}}
      >
        <div
          className={`${
            fullScreen
              ? "w-full h-full "
              : `${
                  fullWidth
                    ? "w-full"
                    : autoWidth
                    ? "w-auto max-w-640"
                    : "w-11/12 max-w-640"
                } `
          }   max-h-full absolute z-40`}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              onClick={closemodal}
              className={`${
                fullScreen ? "fixed md:absolute" : "fixed"
              }  top-0 left-0 w-full inset-0 opacity-30`}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              style={style}
              className={`inline-block w-full  p-4 md:p-6  ${
                fullScreen ? "" : "shadow-xl shadow-cyan-800/60 border border-borderSolid rounded-2xl my-4"
              }  text-left align-middle transition-all transform bg-white dark:bg-dark-light ${modalCss}`}
            >
              {title.trim().length > 0 && (
                <Dialog.Title
                  as="h3"
                  className="text-lg text-center font-medium leading-6 text-title dark:text-titlegit"
                >
                  <button>{title}</button>
                </Dialog.Title>
              )}
              <>
                {showHeaderBar && (
                  <HeaderBar showBackButton onBackClick={_closemodal} />
                )}
                {React.cloneElement(children, { closemodal: _closemodal })}
              </>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalWrapper;
