import { useEffect, useState } from "react"
import { BASE_URL, FETCH_DEFAULT_FORM_HEADERS, FETCH_DEFAULT_HEADERS } from "./Constants"
import { toast } from "react-toastify";
import { appendVersionSymbol, customToast, isDesktop, toEnglishDigit } from "../Utils/General";
import SecureStorage from '../Utils/SecureStorage';
import { REQUEST_TIME_EXPIRY } from "../Utils/Constants";
import { isHybrid } from '../Utils/General';
import { cq } from '../index';

var pjson = require('../../package.json')

const generateHeaders = () => {
  return {
    'VersionCode': parseInt(pjson.version.replace(/\./g, '')),
    'Version': appendVersionSymbol(pjson.version,true),
    'AppType': isHybrid() ? 'Hybrid' : 'PWA'
  }
}
export const InternalCallApi = async (data, useBaseUrl = true) => {

  const lastRequest = localStorage.getItem('LR')
  if (isDesktop() && lastRequest != null) {
    const lastRequestDate = new Date(lastRequest);
    if ((new Date() - lastRequestDate) / 1000 > REQUEST_TIME_EXPIRY) {
      doLogout()
      return null
    }


    localStorage.setItem('LR', new Date())
  }


  if (isDesktop() && sessionStorage.getItem('RM') == null && localStorage.getItem('RM') == 'false') {
    doLogout()
    return null
  }


  const token = `Bearer ${getAuthorization()}`
  if (data.data instanceof FormData) {
    if (isHybrid())
      console.log(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
        headers: {...generateHeaders(), ...FETCH_DEFAULT_FORM_HEADERS, ...(!data?.headers?.Authorization && { Authorization: token }), ...data?.headers },
        method: data?.method,
        body: data.data
      })
    else console.log(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
      headers: { ...generateHeaders(),...FETCH_DEFAULT_FORM_HEADERS, ...(!data?.headers?.Authorization && { Authorization: token }), ...data?.headers },
      method: data?.method,
      body: data.data
    })

    let response = fetch(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
      headers: { ...generateHeaders(),...FETCH_DEFAULT_FORM_HEADERS, ...(!data?.headers?.Authorization && { Authorization: token }), ...data?.headers },
      method: data?.method,
      body: data.data
    })
    response = await handleError(response)
    return response
  } else {
    if (isHybrid())
      console.log(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
        headers: { ...generateHeaders(),...FETCH_DEFAULT_HEADERS, ...data?.headers, ...(!data?.headers?.Authorization && { Authorization: token }) },
        method: data?.method,
        body: toEnglishDigit(JSON.stringify(data.data))
      })
    else console.log(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
      headers: { ...generateHeaders(),...FETCH_DEFAULT_HEADERS, ...data?.headers, ...(!data?.headers?.Authorization && { Authorization: token }) },
      method: data?.method,
      body: toEnglishDigit(JSON.stringify(data.data))
    })

    let response = fetch(`${useBaseUrl ? BASE_URL : ''}${data.url}`, {
      headers: { ...generateHeaders(),...FETCH_DEFAULT_HEADERS, ...data?.headers, ...(!data?.headers?.Authorization && { Authorization: token }) },
      method: data?.method,
      body: toEnglishDigit(JSON.stringify(data.data))
    })
    response = await handleError(response)
    return response
  }
}
const doLogout = () => {
  if (window.location.hash != '#/loginpass' && window.location.hash != '#/otp' && window.location.hash != '#/forgetpass') {
    localStorage.removeItem('UserState')
    localStorage.removeItem('installAndroidModal')
    localStorage.removeItem('STOCK')
    localStorage.removeItem('RC_FP')
    window.location.replace("/#/loginpass")
  }
}
export const useApi = (defaultData = null, defaultLoadingValue = false, silent = false) => {
  try {
    const [loading, setLoading] = useState(defaultLoadingValue)
    const callApi = async (data = null, useBaseUrl = true, isSilent = false, loadOneTime = false) => {
      const _data = data == null ? defaultData : data
      if (_data == null)
        throw Error('data must not be null')

      if (!loadOneTime) {
        setLoading(true)
      }
      const response = await InternalCallApi(_data, useBaseUrl)

      if (response == null) {
        setLoading(false)
        return { success: false, message: { pr: 'نیاز به ورود مجدد' } };
      }

      const jsonResonse = await response.json()
      if (jsonResonse.code == -5) {
        jsonResonse.message.pr = 'نیاز به ورود مجدد'
      }
      if (!silent && !isSilent)
        handleNotifications(jsonResonse)

      cq.unshift({ request: { ..._data }, response: { ...jsonResonse } })

      setLoading(false)

      if (isHybrid())
        console.log(JSON.stringify({ ...jsonResonse, status: response?.status }))
      else console.log("response==>", { ...jsonResonse, status: response?.status })

      return { ...jsonResonse, status: response?.status }
    }

    return [loading, callApi]
  } catch (error) {
    console.log('fetch error', error)
    return null
  }

}

export const useApiInstant = (defaultData = null) => {

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const callApi = async (data = null) => {

    if (data == null)
      throw Error('data must not be null')

    setLoading(true)
    const response = await InternalCallApi(data)
    setLoading(false)
    setResponse(response)
  }

  useEffect(() => {
    callApi(defaultData)
  }, [])

  return [loading, response]
}


export const setDefaults = () => {
  // axios.defaults.baseURL = 'http://api.test.hamrahbours.ir';
  // axios.defaults.headers.post['Content-Type'] = 'application/json';
  // axios.defaults.headers.common['ApiKey'] = API_KEY;
  // axios.defaults.timeout = 20000;
  // axios.defaults.headers.common['VersionCode']=parseInt(pjson.version.replace(/\./g, ''))
  // axios.defaults.headers.common['Version']=appendVersionSymbol(pjson.version)
  // axios.defaults.headers.common['Type']=isHybrid()?'Hybrid':'PWA'
  // axios.defaults.headers.common['Accept'] = 'application/json'
}

export const setAuthorization = (token) => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const getAuthorization = () => {
  const _user = SecureStorage().getItem('UserState')

  return JSON.parse(_user)?.Token
}


const handleError = async (response) => {


  let customResponse = {};

  try {
    const _response = await response;
    const status = _response.status
    if (!_response?.ok) {
      customResponse = _response;//.json = async () => ({ message: { pr: 'مشکلی پیش آمده است ، لطفا مجددا تلاش کنید' }, success: false, response: _response })
      if (_response.status == 401 || _response.status == 403) {
        doLogout()
      }
    } else customResponse = response
    return customResponse
  } catch (er) {
    console.log(JSON.stringify(er))
    toast.dismiss()
    customToast('error','خطا در ارتباط با شبکه')
    // window.location.href = '/#NetError'
    try {
      customResponse.json = async () => ({
        code: -1000,
        message: { pr: 'خطا در ارتباط با شبکه', reason: { message: JSON.stringify(er) } },
        success: false,
        response: null
      })
    }
    catch (e) {
      customResponse.json = async () => ({
        code: -1000,
        message: { pr: 'خطا در ارتباط با شبکه' },
        success: false,
        response: null
      })
    }

    return customResponse
  }


}


const handleNotifications = async (jr) => {

  try {
    if (jr.success == true) return;
    if (jr.code == -1000) return
    toast.dismiss();
    console.log(jr)
    customToast('error', jr.message.pr)
  } catch (er) {
  }


}