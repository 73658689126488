const baseColors = {
    Brand: {
      Light: '#BABCE2',
      Default: '#1E208D',
      Dark: '#090B57'
    },
    DarkMode: {
      Light: '#233040',
      Default: '#1D2733',
      Dark: '$16202A'
    },
    Accent: {
      Light: '#1e208d',
      Dark: '#3639a3',
      Negative: '#c8cbda',
      NegativeDark: '#505A68'
    },
    Error: {
      Light: '#FF5E56',
      Dark: '#a40800'
    },
    Accept: {
      Light: '#03D7AE',
      Dark: '#016B57'
    },
    Attention: {
      Default: '#F5901E',
      Light: '#f8b060'
    },
    Actions: {
      Default: '#00C0C0',
      Light: '#C8CBDA',
        dark: '#505A68'
    }
    ,
    Title: 'var(--Title)',
    Highlightbackground: 'var(--Highlightbackground)',
    SolidBackground: '#F0F1F6',
    Details: '#B1B4C1',
    BodyText: '#5B5C63',
  
  }

  module.exports={baseColors}