import { Message as MessageConstant,Faq } from './../Constants';
import { post, postForm } from './Utils';
import { getAuthorization } from './../index';
const Message={
    getAllMessages:(data)=>({url:MessageConstant.All,...post,data}),
    addNewMessage:(data)=>({url:MessageConstant.Add,...post,data}),
    getAllFaq:(data)=>({url:Faq.All,...post,data}),
    getMessageCount:(data)=>({url:MessageConstant.Count,...post,data}),
    readAll:(data)=>({url:MessageConstant.ReadAll,...post,data})
}

export default Message;