import BottomNavigatorMenuItem from "./Components/index";

import homeFilledIcon from "./../../assets/img/home-filled.svg";
import homeIcon from "./../../assets/img/home.svg";
import profileFilledIcon from "./../../assets/img/profile-filled.svg";
import profileIcon from "./../../assets/img/profile.svg";
import basketFilled from "./../../assets/img/basket-filled.svg";
import basket from "./../../assets/img/basket.svg";
import bell from "./../../assets/img/bell-bottom.svg";
import bellFilled from "./../../assets/img/bell-bottom - filled.svg";

import { ReactComponent as MyStock } from "./../../assets/img/mystock.svg";
import { useHookstate } from "@hookstate/core";
import { ThemeState } from "../../State/Theme";
import React from "react";
import { isIOS } from "react-device-detect";
import ProfileBottomNavigatorMenuItemComponent from "./Components/ProfileBottomNavigatorMenuItem-Component";

const BottomNavigatorComponent = ({
  onClickItem = () => {},
  className = "",
}) => {
  const theme = useHookstate(ThemeState).get();

  return (
    <div
      id="BottomNavigatorContainer"
      className={`px-0 ${className} mobile-menu-shadow z-30`}
    >
      <div className={"flex flex-row w-full "}>
        <div className={"flex flex-row flex-1 relative"}>
          <div
            className={
              "absolute absolute-center flex flex-row justify-around w-full"
            }
          >
            <BottomNavigatorMenuItem
              onClick={() => onClickItem("home")}
              label={"خانه"}
              icon={window.location.hash == "#/" ? homeFilledIcon : homeIcon}
            />
            <BottomNavigatorMenuItem
              onClick={() => onClickItem("basket")}
              label={"سبد سهام"}
              icon={
                window.location.hash.startsWith("#/basket")
                  ? basketFilled
                  : basket
              }
            />
          </div>
          <img
            alt=""
            className="-ml-1"
            src={
              theme == "dark"
                ? require("./../../assets/img/menu-right-dark.svg").default
                : require("./../../assets/img/menu-right.svg").default
            }
          />
          <div className={"bg-white flex flex-1"} />
        </div>
        <div
          className={"-mx-px relative"}
          id={"MyStockContainer"}
          onClick={() => onClickItem("mystock")}
        >
          <div
            className={`rounded-full  active:opacity-70 transition-all duration-75 w-16 h-16 absolute ${
                window.location.hash.startsWith("#/mystock")
                    ? "bg-gradient-to-r from-attention-light to-attention pointer-events-none"
                    : "text-amber-400 bg-white border shadow-lg shadow-amber-400/40 border-amber-400 cursor-pointer hover:opacity-70"
            } flex justify-center items-center`}
            style={{
              transform: `translateX(-50%)`,
              left: "50%",
              bottom: "35px",
            }}
          >
            <MyStock
                className={`${
                    window.location.hash.startsWith("#/mystock")
                        ? "fill-white"
                        : "fill-amber-400"
                }`}
            />
          </div>
          <div
            className={`whitespace-nowrap active:opacity-70 transition-all duration-75 absolute text-center w-full text-amber-400 text-wCaption font-bold`}
            style={{
              transform: `translateX(-50%)`,
              left: "50%",
              bottom: ".2rem",
            }}
          >
            سهام من
          </div>
          <img
            alt=""
            src={
              theme == "dark"
                ? require("./../../assets/img/menu-center-dark.svg").default
                : require("./../../assets/img/menu-center.svg").default
            }
          />
        </div>
        <div className={"flex flex-row flex-1 relative"}>
          <div
            className={
              "absolute absolute-center flex flex-row justify-around w-full"
            }
          >
            <BottomNavigatorMenuItem
              onClick={() => onClickItem("bell")}
              label={"گوش به زنگ"}
              icon={
                window.location.hash.startsWith("#/bell") ? bellFilled : bell
              }
            />
            <ProfileBottomNavigatorMenuItemComponent
              onClick={() => onClickItem("profile")}
              label={"پروفایل"}
              icon={
                window.location.hash.startsWith("#/profile")
                  ? profileFilledIcon
                  : profileIcon
              }
            />
          </div>
          <div className={"bg-white flex flex-1"} />
          <img
            className="-mr-1"
            alt=""
            src={
              theme == "dark"
                ? require("./../../assets/img/menu-left-dark.svg").default
                : require("./../../assets/img/menu-left.svg").default
            }
          />
        </div>
      </div>
      <div className={`${isIOS ? "py-2" : "py-1"}  bg-white -mt-1`} />
    </div>
  );
};
export default React.memo(BottomNavigatorComponent);
