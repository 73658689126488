
import HamrahButton from "../General/HamrahButton";
import UpdateItem from "../../Views/Profile/Views/UpdateModal/UpdateItem";
import { getRemoteConfig } from "../../Utils/General";


const ForceUpdateModal = ({onClick, loading, closemodal, canCancel}) => {
  const remoteConfig = getRemoteConfig()
  const version = remoteConfig?.pwaNewItem[0]
  return (
    <div className={'flex flex-col items-center justify-between w-full flex-1 h-full md:pb-0 overflow-hidden'}>
      <div className={' flex-1 overflow-auto h-full w-full pb-5 md:pb-0'}>
        <div className={'border border-borderSolid rounded-2xl p-5 pt-0 m-5'}>
          <div className={'text-brand font-black text-header text-center mb-8 mt-5'}>بروز رسانی</div>
          <div className={'text-title font-semibold text-body text-center mb-5'}>
          </div>
          <UpdateItem version={version?.versionName}
                      isLatestVersion={false}
                      features={version?.changes}/>
        </div>
        <div className="flex flex-row m-5">
          <HamrahButton loading={loading} className={'flex-1'} contentClassName={'px-2'} onClick={onClick}>بروز رسانی
            اپلیکیشن</HamrahButton>
          {canCancel && <HamrahButton contentClassName={'px-2'} onClick={closemodal} className="mr-3"
                                      variant={'cancel'}>بازگشت</HamrahButton>}
        </div>
      </div>

    </div>
  );
};

export default ForceUpdateModal;

