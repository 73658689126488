const UpdateItem = ({features, isLatestVersion, version}) => {
  return (
      <div className="mb-5">
        <div className="flex items-center justify-center gap-x-4 text-right">
          <div className="h-0.5 flex-1 bg-borderSolid"/>
          <div className="text-brand mt-1 font-bold text-sm text-center"> نسخه {version} </div>
          {isLatestVersion &&
              <div className="bg-error text-body rounded-xl px-2 py-1 font-semibold text-white">آخرین نسخه</div>}
          <div className="h-0.5 flex-1 bg-borderSolid"/>
        </div>
        <div className="flex flex-col gap-y-4 my-4">
          {features?.map(feature => <div key={feature.id} className="flex items-center gap-x-4">
            <div className="w-2 h-2 rounded-full bg-brand"/>
            <div className="text-bodyText font-semibold text-right">{feature.title}</div>
          </div>)}
        </div>
      </div>
  );
};

export default UpdateItem;