import { lazy } from "react";

const HomeMainPageHook = lazy(() => import("../Views/Home"));
const SymbolsPageHook = lazy((() =>import("../Views/SymbolsPage")));
const LoginWithOTPMainPage = lazy((() =>import("../Views/Login/LoginWithOTPMainPage/LoginWithOTPMainPage-Component")));
const LoginWithPassMainPage = lazy((() =>import("../Views/Login/LoginWithPassMainPage/LoginWithPassMainPage-Component")));
const RequestOTPMainPage = lazy((() =>import("../Views/Login/RequestOTPMainPage/RequestOTPMainPage-Component")));
const UserPersonalInfoDisplay = lazy((() =>import("../Views/UserInfo/UserPersonalInfoDisplay/UserPersonalInfoDisplay-Component")));
const UserPersonalInfoUpload = lazy((() =>import("../Views/UserInfo/UserPersonalInfoUpload/UserPersonalInfoUpload-Component")));
const StockRightGiveUpHook = lazy((() =>import("../Views/StockRightSection/StockRightGiveUp")));
const MyStockHook = lazy((() =>import("../Views/MyStock")));
const RequestsSuccessPage = lazy((() =>import("../Views/MyStock/views/requests/RequestsSuccessPage")));
const ViewSymbolScreen = lazy((() =>import("../Views/ViewSymbol/ViewSymbol-Component")));
const ViewExtraSymbolScreen = lazy((() =>import("../Views/ViewSymbol/ViewExtraSymbol-Component")));
const BankInfoEditHook = lazy((() =>import("../Views/UserInfo/UserBankInfoDisplay/BankInfoEdit")));
const AddressInfoEditHook  = lazy((() =>import("../Views/UserInfo/UserAddressInfoDisplay/AddressInfoEdit")));
const SejamServices = lazy((() =>import("../Views/SejamServices")));
const SejamInquiry = lazy((() =>import("../Views/SejamServices/SejamInquiry")));
const SejamSms = lazy((() =>import("../Views/SejamServices/SejamSms")));
const SejamDataPreview = lazy((() =>import("../Views/SejamServices/SejamDataPreview/SejamDataPreview-Component")));
const NewsArchiveComponent = lazy((() =>import("../Views/SymbolCodal/Componetns/NewsArchive")));
const NewsDetails = lazy((() =>import("../Views/SymbolCodal/Componetns/NewsDetails")));
const CodalArchive = lazy((() =>import("../Views/SymbolCodal/Componetns/CodalArchive")));
const ChatScreen = lazy((() =>import("../Views/Chat/ChatMainPage-Component")));
const AddSymbolComponent = lazy((() =>import("../Views/AddSymbolPage/index")));
const Profile = lazy((() =>import("../Views/Profile")));
const BasketStartPage = lazy((() =>import("../Views/BasketService/BasketStartPage/BasketStartPage-Component")));
const BasketAddSymbol = lazy((() =>import("../Views/BasketService/BasketAddSymbol/BasketAddSymbol-Component")));
const AddOrEditSymbolInBasket = lazy((() =>import("../Views/BasketService/AddOrEditSymbolInBasket/AddOrEditSymbolInBasket-Component")));
const ViewBasket = lazy((() =>import("../Views/BasketService/ViewBasket")));
const BasketChart = lazy((() =>import("../Views/BasketService/BasketChart/BasketChart-Component")));
const BellStartPage = lazy((() =>import("../Views/BellService/BellStartPage/BellStartPage-Component")));
const GeneralSymbolSearchPage = lazy((() =>import("../Views/GeneralSymbolSearchPage")));
const SelectBasket = lazy((() =>import("../Views/BellService/SelectBasket/SelectBasket-Component")));
const TutorialStartPage = lazy((() =>import("../Views/TutorialService/TutorialStartPage/TutorialStartPage-Component")));
const TutorialList = lazy((() =>import("../Views/TutorialService/TutorialList/TutorialList-Component")));
const TutorialView = lazy((() =>import("../Views/TutorialService/TutorialView")));
const Conversations = lazy((() =>import("../Views/Profile/Views/Conversations/Conversations-Component")));
const Settings = lazy((() =>import("../Views/Profile/Views/Settings")));
const Alerts = lazy((() =>import("../Views/Profile/Views/Alerts/Alerts-Component")));
const ShareApp = lazy((() =>import("../Views/Profile/Views/ShareApp/ShareApp-Component")));
const UserGuideStartPage = lazy((() =>import("../Views/UserGuide/UserGuideStartPage/UserGuideStartPage-Component")));
const Faq = lazy((() =>import("../Views/UserGuide/Faq/Faq-Component")));
const Rules = lazy((() =>import("../Views/Profile/Views/Rules/Rules-Component")));
const StockRightMain = lazy((() =>import("../Views/StockRight/StockRightMain-Component")));
const NetError = lazy((() =>import("../Views/Errors/NetError")));
const JusticeShare = lazy((() =>import("../Views/JusticeShares/JusticeShares-Component")));
const ComapreBaskets = lazy((() =>import("../Views/BasketService/ComapreBaskets/CompareBaskets-Component")));
const EhrazStartPage = lazy((() =>import("../Views/EhrazService/EhrazStartPage/EhrazStartPage-Component")));
const EhrazLogin = lazy((() =>import("../Views/EhrazService/EhrazLogin/EhrazLogin-Component")));
const EhrazOTPEnter = lazy((() =>import("../Views/EhrazService/EhrazOTPEnter/EhrazOTPEnter-Component")));
const EhrezRules = lazy((() =>import("../Views/EhrazService/EhrazRules/EhrazRules-Component")));
const EhrezPayment = lazy((() =>import("../Views/EhrazService/EhrazPayment")));
const EhrazMelliSerial = lazy((() =>import("../Views/EhrazService/EhrazMelliSerial/EhrazMelliSerial-Component")));
const EhrazSelfie = lazy((() =>import("../Views/EhrazService/EhrazSelfie/EhrazSelfie-Component")));
const EhrazSign = lazy((() =>import("../Views/EhrazService/EhrazSign/EhrazSign-Component")));
const EhrazChallengeSelect = lazy((() =>import("../Views/EhrazService/EhrazChallengeSelect/EhrazChallengeSelect-Component")));
const EhrazChallenge = lazy((() =>import("../Views/EhrazService/EhrazChallenge/EhrazChallenge-Component")));
const EhrazReview = lazy((() =>import("../Views/EhrazService/EhrazReview/EhrazReviewComponent")));
const EhrazResult = lazy((() =>import("../Views/EhrazService/EhrazResult/EhrazResult-Component")));
const Calendar = lazy((() =>import("../Views/Calendar/Calendar")));
const RequestsStartPage = lazy((() =>import("../Views/MyStock/views/requests/RequestsStartPage")));
const StockRightMainExpired = lazy((() =>import("../Views/StockRight/ExpiredStockRight/ExpiredStockRightMain")));
const EhrazResultError = lazy((() =>import("../Views/EhrazService/EhrazResultError/EhrazResultError-Component")));
const TicketScreen = lazy((() =>import("../Views/Ticket")));
const ContactUs = lazy((() =>import("../Views/Profile/Views/ContactUs")));
const RequestsResultsPage = lazy((() =>import("../Views/MyStock/views/requests/RequestsResultsPage")));
const Content = lazy((() =>import("../Views/Profile/Views/features/components/Content/Content")));
const LawyerIntroPage = lazy((() =>import("../Views/MyStock/views/lawyer/LawyerIntroPage")));
const LawyerStartPage = lazy((() =>import("../Views/MyStock/views/lawyer/LawyerStartPage")));
const LawyerResultPage = lazy((() =>import("../Views/MyStock/views/lawyer/LawyerResultPage")));
const LoginStartPage = lazy((() =>import("../Views/Login/LoginStartPage/LoginStartPage")));
const PayamNazer = lazy((() =>import("../Views/PayamNazer")));
const Prices = lazy((() =>import("../Views/Prices")));
const UnusedStockRight = lazy((() =>import("../Views/UnusedStockRight")));
const Tablokhani = lazy((() =>import("../Views/Tablokhani"))) ;
const NewsScreen = lazy((() =>import("../Views/News")));
const Partners = lazy((() =>import("../Views/MyStock/views/partners/Partners")));
const AppError = lazy((() =>import("../Views/Errors/AppError")));
const BourseKala = lazy((() =>import("../Views/BourseKala")));
const StockRightFaqs = lazy((() =>import("../Views/StockRight/StockRightFaqs")));


const roues = [
    {path: '/news', exact: true,component: NewsScreen},
    {path: '/tablokhani', exact: true,component: Tablokhani},
    {path: '/unusedstock/:companyid/:userstockid', exact: true,component: UnusedStockRight},
    {path: '/prices', exact: true,component: Prices},
    {path: '/payamnazer/:companyid', exact: true,component: PayamNazer},
    {path: '/apperror', exact: true,component: AppError},
    {path: '/partners', exact: true,component: Partners},
    {path: '/contactus', exact: true,component: ContactUs},
    {path: '/ticket', exact: true,component: TicketScreen},
    {path: '/ehrazresulterror', exact: true,component: EhrazResultError},
    {path: '/ehrazresult/:trackid', exact: true,component: EhrazResult},
    {path: '/ehrazreview/:meli', exact: true,component: EhrazReview},
    {path: '/ehrazchallenge/:meli/:type', exact: true,component: EhrazChallenge},
    {path: '/ehrazchallengeselect/:meli', exact: true,component: EhrazChallengeSelect},
    {path: '/ehrazsign/:meli', exact: true,component: EhrazSign},
    {path: '/ehrazselfie/:meli', exact: true,component: EhrazSelfie},
    {path: '/ehrazmelliserial/:meli', exact: true,component: EhrazMelliSerial},
    {path: '/ehrazpayment/:meli', exact: true,component: EhrezPayment},
    {path: '/ehrazrules/:meli', exact: true,component: EhrezRules},
    {path: '/ehrazotp/:meli/:sejam', exact: true,component: EhrazOTPEnter},
    {path: '/ehrazlogin', exact: true,component: EhrazLogin},
    {path: '/ehraz', exact: true,component: EhrazStartPage},
    {path: '/comparecompany/:symbol/:number/:price/:date/:value', exact: true,component: ComapreBaskets},
    {path: '/calendar', exact: true,component: Calendar},
    {path: '/comparebasket/:id', exact: true,component: ComapreBaskets},
    {path: '/justiceshare', exact: true,component: JusticeShare},
    {path: '/NetError', exact: true,component: NetError},
    {path: '/rules', exact: true,component: Rules},
    {path: '/faq/:id', exact: true,component: Faq},
    {path: '/features', exact: true,component: Content},
    {path: '/userguide/:tab?', exact: true,component: UserGuideStartPage},
    {path: '/share', exact: true,component: ShareApp},
    {path: '/alerts', exact: true,component: Alerts},
    {path: '/settings/:tab?', exact: true,component: Settings},
    {path: '/conversations', exact: true,component: Conversations},
    {path: '/viewtutorial/:id', exact: true,component: TutorialView},
    {path: '/tutorial/:id/:title', exact: true,component: TutorialList},
    {path: '/tutorial', exact: true,component: TutorialStartPage},
    {path: '/bell/selectbasket', exact: true,component: SelectBasket},
    {path: '/bell/selectsymbol/:type', exact: false,component: GeneralSymbolSearchPage},
    {path: '/bell', exact: true,component: BellStartPage},
    {path: '/basketeditsymbols/:BasketId/:SymbolBasketId/:CompanyId/:Number/:Price/:Date/:Wage', exact: false,component: AddOrEditSymbolInBasket},
    {path: '/basketview/:id', exact: false,component: ViewBasket},
    {path: '/basketeditsymbol/:BasketId/:CompanyId/:Number?/:Price?/:Date?', exact: false,component: AddOrEditSymbolInBasket},
    {path: '/basketedit/:BasketId', exact: false,component: BasketAddSymbol},
    {path: '/basketaddsymbol/:title/:CompanyId', exact: false,component: AddOrEditSymbolInBasket},
    {path: '/basketadd/:title', exact: false,component: BasketAddSymbol},
    {path: '/basketchart/:BasketId', exact: false,component: BasketChart},
    {path: '/basket', exact: false,component: BasketStartPage},
    {path: '/profile', exact: false,component: Profile},
    {path: '/addsymbol', exact: false,component: AddSymbolComponent},
    {path: '/chat/:companyId', exact: false,component: ChatScreen},
    {path: '/codalarchive/:companyId/:type/:title', exact: false,component: CodalArchive},
    {path: '/newsdetails/:CompanyId/:NewsId', exact: false,component: NewsDetails},
    {path: '/newsarchive/:CompanyId', exact: false,component: NewsArchiveComponent},
    {path: '/Sejamdata', exact: false,component: SejamDataPreview},
    {path: '/Sejamsms', exact: false,component: SejamSms},
    {path: '/Sejaminquiry', exact: false,component: SejamInquiry},
    {path: '/sejam', exact: false,component: SejamServices},
    {path: '/mystock/lawyerResult', exact: true,component: LawyerResultPage},
    {path: '/mystock/lawyerRequest', exact: true,component: LawyerStartPage},
    {path: '/mystock/lawyerIntro', exact: true,component: LawyerIntroPage},
    {path: '/mystock/requests/results', exact: true,component: RequestsResultsPage},
    {path: '/mystock/requests/success', exact: true,component: RequestsSuccessPage},
    {path: '/mystock/requests', exact: true,component: RequestsStartPage},
    {path: '/mystock', exact: true,component: MyStockHook},
    {path: '/viewsymbol/:CompanyId/:UserStockId?', exact: false,component: ViewSymbolScreen},
    {path: '/viewextrasymbol/:CompanyId/:UserStockId?', exact: false,component: ViewExtraSymbolScreen},
    {path: '/giveup', exact: false,component: StockRightGiveUpHook},
    {path: '/stockrightexpired/:CompanyId/:UserStockId', exact: false,component: StockRightMainExpired},
    {path: '/stockright/:CompanyId/:UserStockId', exact: false,component: StockRightMain},
    {path: '/symbols/:companyId', exact: false,component: SymbolsPageHook},
    {path: '/userbankinfodisplay', exact: false,component: BankInfoEditHook},
    {path: '/userpinfoupload', exact: false,component: UserPersonalInfoUpload},
    {path: '/useraddressinfo', exact: false,component: AddressInfoEditHook},
    {path: '/userpinfo', exact: false,component: UserPersonalInfoDisplay},
    {path: '/forgetpass', exact: false,component: RequestOTPMainPage},
    {path: '/loginotpforget', exact: false,component: LoginWithOTPMainPage},
    {path: '/loginotp', exact: false,component: LoginWithOTPMainPage},
    {path: '/otp', exact: false,component: RequestOTPMainPage},
    {path: '/loginpass', exact: false,component: LoginWithPassMainPage},
    {path: '/login', exact: false,component: LoginStartPage},
    {path: '/', exact: true,component: HomeMainPageHook},
    {path: '/bourseKala', exact: true,component: BourseKala},
    {path: '/stockrightfaqs', exact: true,component: StockRightFaqs},
]

export  default roues