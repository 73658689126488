import Logo from "./../../assets/img/Logo";
import { deepSetState, usePersistState, UserState } from "../../State";
import { Link, useHistory, useLocation } from "react-router-dom";
import profileFilledIcon from "../../assets/img/profile-filled.svg";
import profileIcon from "../../assets/img/profile.svg";
import React, { useCallback, useRef, useState } from "react";
import settingIconFilled from "../../assets/img/settings-filled.svg";
import settingIcon from "../../assets/img/setting.svg";
import logOutIcon from "../../assets/img/Logout.svg";
import HamrahButton from "../General/HamrahButton";
import ModalWrapper from "../General/ModalWrapper";
import { UserStocks } from "../../State/Stocks";
import {
  clearAlerts,
  setRemoteConfig,
  useOnClickOutside,
} from "../../Utils/General";
import { ReactComponent as Home } from "../../assets/img/header-home.svg";
import Marquee from "react-fast-marquee";
import { ReactComponent as TrendingIcon } from "../../assets/img/trending.svg";
import { useHookstate } from "@hookstate/core";
import { PartnersState } from "../../State/Partners";

const WebMenuItem = ({
  isActive = false,
  className = "",
  icon = null,
  label = "",
  disabled = false,
}) => {
  return (
    <div
      className={` flex flex-row items-center justify-between w-full ${className} ${
        disabled ? "opacity-30 pointer-events-none" : ""
      }`}
    >
      <div
        className={`${
          isActive ? "pointer-events-none" : ""
        } hover:opacity-70 cursor-pointer transition-all active:opacity-70 flex flex-row flex-1 items-center justify-start `}
      >
        <img
          alt="icon"
          src={icon}
          className={`w-6 ${label == "خروج" ? "-mr-1" : ""}`}
        />
        <div
          className={`text-wCaption font-bold ${
            isActive ? "text-brand" : "text-bodyText"
          } mr-3 text-right`}
        >
          {label}
        </div>
      </div>
    </div>
  );
};

const HeaderBarComponent = ({ showBackButton = false, onBackClick }) => {
  const history = useHistory();
  const userState = usePersistState(UserState, "UserState");
  const [showLogoutModal, onSetShowLogoutModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();
  const partners = useHookstate(PartnersState).get();

  useOnClickOutside(menuRef, () => {
    if (openModal) setOpenModal(false);
  });

  const onLogout = () => {
    deepSetState(UserState, "", true, "UserState");
    deepSetState(UserStocks, "", true, "STOCK");
    setRemoteConfig(null);
    localStorage.setItem("ACCEPTED_SHOW", "NO");
    clearAlerts();
    history.push("/loginpass");
  };

  const pricePercentChange = useCallback((company) => {
    const PriceChangePercent =
      company?.LastTradedPrice / company?.PriceYesterday - 1;

    return (
      (Math.ceil(Math.abs(PriceChangePercent) * 10e3) / 10e1) *
      (PriceChangePercent < 0 ? -1 : 1)
    );
  }, []);

  return (
    <div
      id={"HeaderBarContainer"}
      className={
        "relative bg-brand dark:bg-dark flex justify-center md:justify-between items-center min-h-14 md:px-10 w-full"
      }
      style={{ zIndex: 100000 }}
    >
      {location.pathname !== "/" && (
        <Link
          to="/"
          className={`bg-white absolute top-1/2 right-0 transform -translate-y-1/2 mx-5 md:hidden cursor-pointer rounded-full h-10 w-10 flex justify-center items-center`}
        >
          <Home style={{ width: "20px", height: "20px" }} />
        </Link>
      )}
      <div className={`h-5 ${showBackButton ? "flex-1 -ml-10" : ""}`}>
        <Link to="/" className={"outline-none"}>
          <Logo color={"white"} />
        </Link>
      </div>
      {location?.pathname === '/' &&
          <div className="flex-1 h-full px-5 text-black hidden md:flex items-center justify-center mx-2 overflow-hidden ltr">
            <Marquee
                speed={20}
                direction="right"
                pauseOnHover
                gradientColor={[0, 0, 0, 0]}
            >
              {partners?.map((company,index) => (
                  <div
                      key={company?.Id}
                      className={`flex ltr text-sm items-center justify-center mx-2 ${index === partners?.length - 1 ? "mr-72" : ""}`}
                  >
                    <div
                        className={`text-black mr-2 rounded-md ${
                            company?.PriceChange > 0
                                ? "bg-green-700"
                                : company?.PriceChange === 0
                                    ? "bg-white"
                                    : "bg-error"
                        } px-2 flex items-center justify-center`}
                    >
                      {pricePercentChange(company)}%
                      {company?.PriceChange !== 0 && (
                          <TrendingIcon
                              className={`h-4 text-black mx-auto ${
                                  company?.PriceChange < 0
                                      ? "transform rotate-180 -scale-x-100"
                                      : ""
                              }`}
                          />
                      )}
                    </div>
                    <div className="font-black"> : {company?.Symbol}</div>
                  </div>
              ))}
            </Marquee>
          </div>
      }
      <div className={"flex-row items-center justify-center md:flex hidden"}>
        <Link to="/profile">
          <div
            className={"text-white dark:text-title text-wBody font-semibold"}
          >
            {!userState?.get()?.FirstName
              ? "کاربر مهمان"
              : `${userState?.get()?.FirstName.replace(/"/g, "")} ${userState
                  ?.get()
                  ?.LastName.replace(/"/g, "")}`}
          </div>
        </Link>
        <div
          ref={menuRef}
          onClick={() => setOpenModal(!openModal)}
          className="mr-2 relative cursor-pointer"
        >
          <img
            src={require("../../assets/img/arrowDownWhite.svg").default}
            className={`transition-all transform duration-150 ${
              openModal ? "rotate-180" : "rotate-0"
            }`}
            alt="arrow"
          />
          <div
            className={`${
              openModal ? "block" : "hidden"
            } absolute top-8 px-4 pb-3 left-0 rounded-xl shadow-2xl border border-details dark:border-borderSolid bg-white w-48`}
          >
            <div>
              <Link to="/profile">
                <WebMenuItem
                  icon={
                    window.location.hash.startsWith("#/profile")
                      ? profileFilledIcon
                      : profileIcon
                  }
                  label={"پروفایل"}
                  className="mt-4 icon"
                  isActive={window.location.hash == "#/profile"}
                />
              </Link>
            </div>
            <div>
              <Link to="/settings">
                <WebMenuItem
                  icon={
                    window.location.hash.startsWith("#/settings")
                      ? settingIconFilled
                      : settingIcon
                  }
                  label={"تنظیمات"}
                  isActive={window.location.hash.startsWith("#/settings")}
                  className={"mt-4 icon"}
                />
              </Link>
            </div>
            <div>
              <div onClick={() => onSetShowLogoutModal(true)}>
                <WebMenuItem
                  icon={logOutIcon}
                  label={"خروج"}
                  className="mt-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showBackButton && (
        <div
          className={"p-5 active:opacity-70 flex md:hidden w-15"}
          onClick={onBackClick}
        >
          <img
            alt="arrow"
            src={require("./../../assets/img/arrow-left.svg").default}
          />
        </div>
      )}
      <ModalWrapper
        isOpen={showLogoutModal}
        closemodal={() => onSetShowLogoutModal(false)}
      >
        <div className={"p-3"}>
          <div className={"font-black text-title text-header text-center"}>
            آیا مایل به خروج از حساب کاربری خود هستید؟
          </div>
          <div className={"grid grid-cols-5 gap-4 mt-5"}>
            <HamrahButton
              className="col-span-2"
              onClick={() => onSetShowLogoutModal(false)}
              variant={"cancel"}
            >
              خیر
            </HamrahButton>
            <HamrahButton
              className="col-span-3"
              onClick={onLogout}
              variant={"error"}
            >
              بله، خارج شو
            </HamrahButton>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default React.memo(HeaderBarComponent);
