import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HamrahButton from "./General/HamrahButton";
import ModalWrapper from "./General/ModalWrapper";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { deepSetState, usePersistState, UserState } from "../State";
import { useApi } from "../Api/index";
import { getPlans } from "../Api/Actions/Init";
import Skeleton from "react-loading-skeleton";
import {
  getRemoteConfig,
  getTailwindScreenSize,
  isHybrid,
  toCommas,
} from "../Utils/General";
import {
  bePremium,
  startFreeTime,
  validateCafebazaar,
  validateMyket,
} from "../Api/Actions/user";
import { toast } from "react-toastify";
import {
  AppSectionPermissionTypeValue,
  SliderPlanIndex,
} from "../Utils/Constants";
import { logEvent } from "../Utils/FirebaseLog";
import bg from "./../assets/img/pback.png";
import { ThemeState } from "../State/Theme";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "../Api/Actions/Authentication";
import moment from "moment-jalaali";
import { isMobile } from "react-device-detect";
import GoldIcon from "../assets/img/gold.png";
import SilverIcon from "../assets/img/silver.png";
import BronzeIcon from "../assets/img/bronze.png";

const settings = {
  centerMode: isMobile,
  infinite: false,
  centerPadding: !isMobile ? "0px" : "30px",
  slidesToShow: !isMobile ? 3 : 1,
  speed: 1000,
  arrows: false,
  dots: true,
  dotsClass: "custom-dots-light",
};

const MemberShipModal = ({ closemodal, permissionType }) => {
  const [currentSlide, setCurrentSlide] = useState(
    getTailwindScreenSize() !== "sm" ? null : 0
  );
  const [packages, setPackages] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(-1);
  const me = usePersistState(UserState, "UserState").get();
  const remoteConfig = getRemoteConfig();

  const [loading, callApi] = useApi();
  const [waitingForBank, callPremiumApi] = useApi();
  const [plans, setPlans] = useState([]);
  const slider = useRef(null);
  const history = useHistory();

  const _closeModal = () => {
    closemodal();
  };

  const onMyketPurchaseResult = useCallback(async (result) => {
    const type = result.detail.value.type;
    if (type == "purchaseSucceed") {
      const data = JSON.parse(result.detail.value.value);
      const { purchaseToken, productId } = data;

      const response = await callApi(
        validateMyket({
          PurchaseToken: purchaseToken,
          SubscriptionId: productId,
          Amount: parseInt(result.detail.value.amount),
        })
      );

      if (response.status == 200) {
        const responseMe = await callApi(getUserProfile());

        if (responseMe.success) {
          deepSetState(
            UserState,
            { ...me, ...responseMe.data },
            true,
            "UserState"
          );
        }

        logEvent("PWA_successPaymentMyket");
        _closeModal();
        history.push("/");
      } else if (
        response.status === 409 &&
        !response.success &&
        response.code === -12
      ) {
        toast.error({
          text: `این سرویس قبلا برای شما فعال شده است`,
        });
      }
    } else if (type == "failedToBeginFlow") {
      toast.error("خطا در پرداخت درون برنامه ای قبل از شروع فرآیند");
    } else if (type == "purchaseFailed") {
      toast.error("خطا در پرداخت درون برنامه ای");
    } else if (type == "purchaseCanceled") {
      toast.error("انصراف از پرداخت");
    }
  }, []);

  const onPurchaseResult = useCallback(async (result) => {
    //"{"value":{"type":"purchaseSucceed","value":"{\"dataSignature\":\"M0oAcFAbh7xp9emsmavdUxP/TuFEE+LMmCjPubU/eZuEC5wF7WtBpiW+qBuce9hqf73h90Fs5Byhlk8kQMJwhPg8231oh9NaV9Znq+czV+gQO+D6jHAao2FcsdukrC1z0yZToW5NrhNKvSDtcQnU0va7rkcroYRbtj58cDa0Cs7CwvqLoxXmAqfvxV0WV0LRjj/kvOtVX2kZUab7wGj/1eo0uqX/K77CmODx0Tda\",\"orderId\":\"nU_Oz0dhxSz5P3P3\",\"originalJson\":\"{\\\"orderId\\\": \\\"nU_Oz0dhxSz5P3P3\\\", \\\"packageName\\\": \\\"com.hamrahbours\\\", \\\"productId\\\": \\\"Test2\\\", \\\"purchaseTime\\\": 1645518594368, \\\"purchaseState\\\": 0, \\\"developerPayload\\\": \\\"\\\", \\\"purchaseToken\\\": \\\"nU_Oz0dhxSz5P3P3\\\"}\",\"packageName\":\"com.hamrahbours\",\"payload\":\"\",\"productId\":\"Test2\",\"purchaseState\":\"PURCHASED\",\"purchaseTime\":1645518594368,\"purchaseToken\":\"nU_Oz0dhxSz5P3P3\"}"}}"

    const type = result.detail.value.type;
    if (type == "purchaseSucceed") {
      const data = JSON.parse(result.detail.value.value);
      const { purchaseToken, productId } = data;

      localStorage.setItem(
        "inAppPurchaseBazaar",
        JSON.stringify({
          purchaseToken: purchaseToken,
          productId: productId,
          Amount: result.detail.value.amount,
        })
      );

      const response = await callApi(
        validateCafebazaar({
          PurchaseToken: purchaseToken,
          SubscriptionId: productId,
          Amount: parseInt(result.detail.value.amount),
        })
      );

      if (response.status == 200) {
        const responseMe = await callApi(getUserProfile());

        if (responseMe.success) {
          deepSetState(
            UserState,
            { ...me, ...responseMe.data },
            true,
            "UserState"
          );
        }

        localStorage.removeItem("inAppPurchaseBazaar");
        logEvent("PWA_successPaymentBazaar");
        _closeModal();
        history.push("/");
      } else if (
        response.status === 409 &&
        !response.success &&
        response.code === -12
      ) {
        toast.error({
          text: `این سرویس قبلا برای شما فعال شده است`,
        });
      }
    } else if (type == "failedToBeginFlow") {
      toast.error("خطا در پرداخت درون برنامه ای قبل از شروع فرآیند");
    } else if (type == "purchaseFailed") {
      toast.error("خطا در پرداخت درون برنامه ای");
    } else if (type == "purchaseCanceled") {
      toast.error("انصراف از پرداخت");
    }
  }, []);

  useEffect(() => {
    (async () => {
      const levels = remoteConfig?.payment?.filter((p) => p.level != 0);
      setPackages(levels?.reverse());
      const response = await callApi(getPlans({ freeMode: true }));
      if (response.success) {
        setPlans(response.data);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    if (isHybrid()) {
      //wait until the element is inserted into the DOM
      let mainWrapper = document.getElementById("main-wrapper");
      const interval = setInterval(() => {
        mainWrapper = document.getElementById("main-wrapper");
        if (mainWrapper != null) {
          clearInterval(interval);
          mainWrapper.removeEventListener("becomePremium", becomePremium);
          mainWrapper.addEventListener("becomePremium", becomePremium);

          mainWrapper.removeEventListener("onPurchaseResult", onPurchaseResult);
          mainWrapper.addEventListener("onPurchaseResult", onPurchaseResult);

          mainWrapper.removeEventListener(
            "onMyketPurchaseResult",
            onMyketPurchaseResult
          );
          mainWrapper.addEventListener(
            "onMyketPurchaseResult",
            onMyketPurchaseResult
          );
        }
      }, 250);
    }
  }, []);

  const becomePremium = useCallback(async (event) => {
    let cleanUrl = window.location.href;
    if (window.location.href.indexOf("?") >= 0)
      cleanUrl = window?.location?.href.substr(
        0,
        window.location.href.indexOf("?")
      );

    // if (isHybrid())
    //     cleanUrl = 'hbbs://openapp'

    const response = await callPremiumApi(
      bePremium({ PlanId: event.detail.value.planId, RedirectUrl: cleanUrl })
    );
    if (response.success) {
      window.location.href = response.data.Url;
    }
  });

  useEffect(() => {
    try {
      if (permissionType && slider.current)
        slider.current["slickGoTo"](
          SliderPlanIndex[
            [...AppSectionPermissionTypeValue[permissionType]].reverse()[0]
          ],
          true
        );
    } catch (err) {}
  }, [plans]);

  return (
    <ModalWrapper
      fullWidth={getTailwindScreenSize() != "sm"}
      fullScreen
      isOpen={true}
      modalCss={"!p-0 min-h-screen"}
    >
      <div
        id={"main-wrapper"}
        className={
          "min-h-screen flex flex-col justify-center relative bg-whiteBackground dark:bg-solid"
        }
      >
        <div
          className={"absolute w-full h-full top-0 left-0 -z-1 opacity-10 "}
          style={{ backgroundImage: `url(${bg})` }}
        />
        <div
          onClick={closemodal}
          className={"absolute left-5 top-7 w-5 cursor-pointer"}
        >
          <img
            alt="arrow"
            src={
              ThemeState.get() == "light"
                ? require("./../assets/img/arrow-left-light.svg").default
                : require("./../assets/img/arrow-left.svg").default
            }
          />
        </div>

        <img
          alt="logo"
          src={
            ThemeState.get() == "light"
              ? require("./../assets/img/logo.svg").default
              : require("./../assets/img/logo-light.svg").default
          }
          className={"w-32 mx-auto m-5"}
        />
        <div
          className={
            "p-0 block w-full md:max-w-desktop-bg mx-auto relative py-8 pb-0"
          }
        >
          <div className={"text-center p-5 text-title font-semibold"}>
            توجه: پرداخت حق تقدم و استفاده از سرویس تنظیم گوش به زنگ در قسمت
            "سهام من" برای سهامداران نمادهای طرف قرارداد کاملا رایگان است.
          </div>
          <Slider
            ref={(s) => (slider.current = s)}
            initialSlide={currentSlide}
            className={"w-full"}
            {...settings}
            beforeChange={(old, current) =>
              getTailwindScreenSize() == "sm"
                ? setTimeout(() => setCurrentSlide(current), 100)
                : null
            }
          >
            {packages &&
              packages?.map((p, index) => (
                <div key={p.Id}>
                  <div
                    style={{ maxHeight: "calc(100vh - 8rem)" }}
                    className={"flex justify-center items-center"}
                  >
                    <UpgradeItem
                      prices={plans?.filter((pp) => pp.Level == p.level)}
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      loading={loading}
                      waitingForBankExterntal={waitingForBank}
                      data={p}
                      className={`${
                        currentSlide == index ? "scale-100" : "scale-90"
                      }`}
                    />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </ModalWrapper>
  );
};

const PackageIcons = {
  3: GoldIcon,
  2: SilverIcon,
  1: BronzeIcon,
};
const TimeTypes = {
  30: "یکماهه",
  90: "سه ماهه",
};
const FeatureItem = ({ data }) => {
  return (
    <div className={"flex flex-row items-center justify-start mt-2"}>
      {data?.permission && (
        <div>
          <img
            alt="tik"
            src={require("./../assets/img/tick_square.svg").default}
          />
        </div>
      )}
      {!data?.permission && (
        <div>
          <img
            alt="close"
            src={require("./../assets/img/close_square.svg").default}
          />
        </div>
      )}
      <div className={"text-right  mr-3 text-body text-title font-semibold"}>
        {data?.Item}
      </div>
      {/* <div className={'bg-error text-white text-menu font-bold px-2 py-1 rounded-2xl mr-3'}>رایگان</div> */}
    </div>
  );
};

const UpgradeItem = ({
  className = "",
  selectedPlan,
  setSelectedPlan,
  prices = [],
  data = null,
  loading = false,
  waitingForBankExterntal = false,
}) => {
  const [waitingForBank, callPlanApi] = useApi();
  const [waitingForFreeTime, callSilentApi] = useApi(null, false, true);
  const me = usePersistState(UserState, "UserState").get();
  const remoteConfig = getRemoteConfig();

  useEffect(() => {}, []);

  const onFreeTimeActivate = async () => {
    logEvent("PWA_threeDaysFree");

    const response = await callSilentApi(startFreeTime());
    if (response.success) {
      let cleanUrl = window.location.href;
      if (window.location.href.indexOf("?") >= 0)
        cleanUrl = window?.location?.href.substr(
          0,
          window.location.href.indexOf("?")
        );
      window.location.href = cleanUrl;
      toast.success("اشتراک 3 روزه شما با موفقیت فعال شد");
      window.location.reload();
    } else {
      if (response.code == -12 || response?.message?.pr?.includes("تکرار")) {
        toast.dismiss();
        toast.error("شما قبلا اشتراک رایگان را فعال کرده اید");
      } else {
        toast.dismiss();
        toast.error(response?.message?.pr);
      }
    }
  };

  const onPlanSelect = (data) => {
    setSelectedPlan(data?.id);
  };

  const onBuyPackage = async () => {
    if (selectedPlan == -1) {
      toast.dismiss();
      toast.error("لطفا ابتدا یک پکیج را انتخاب کنید");
      return;
    }

    if (moment(me?.PremiumExpireDate).isBefore(me?.Time) || !me?.Plan) {
      logEvent("PWA_buyPackage", { Topic: data?.LevelTitle });
      if (isHybrid()) {
        const inAppPurchaseBazaar = localStorage.getItem("inAppPurchaseBazaar");
        if (inAppPurchaseBazaar !== null) {
          toast.error("برای خرید مجدد لطفا دوباره وارد اپ شوید");
          return;
        }

        const plan = prices?.find((p) => p.id == selectedPlan);
        const amount = plan.Price - plan.Off;
        if (!plan) return;
        if (isHybrid())
          window.Android.onBuyPackage(
            plan.CafeBazaarSKU,
            plan.id,
            amount,
            plan.MyketSKU
          );
      } else await becomePremium();
      return;
    }

    const permissions = remoteConfig?.payment;
    const currentPlan = me?.Plan;
    const currentPlanItem = permissions?.find((p) =>
      p.plansId.includes(currentPlan)
    );

    if (currentPlanItem?.level == data?.level) {
      toast.dismiss();
      toast.error("این سطح قبلا برای شما فعال شده است");
      return;
    }

    if (currentPlanItem?.level != 0 && currentPlanItem?.level < data?.level) {
      toast.dismiss();
      toast.error("شما امکان تنزل پکیج خود را ندارید");
      return;
    }

    logEvent("PWA_buyPackage", { Topic: data?.LevelTitle });
    if (isHybrid()) {
      // const inAppPurchaseBazaar = localStorage.getItem('inAppPurchaseBazaar');
      // if(inAppPurchaseBazaar!==null){
      //     toast.error('برای خرید مجدد لطفا دوباره وارد اپ شوید', {
      //         style: {
      //             marginLeft: !isMobile ? '20%' : ''
      //         }
      //     });
      //     return;
      // }

      const plan = prices?.find((p) => p.id == selectedPlan);
      const amount = plan.Price - plan.Off;
      if (!plan) return;
      if (isHybrid())
        window.Android.onBuyPackage(
          plan.CafeBazaarSKU,
          plan.id,
          amount,
          plan.MyketSKU
        );
    } else await becomePremium();
  };
  const becomePremium = async () => {
    let cleanUrl = window.location.href;
    if (window.location.href.indexOf("?") >= 0)
      cleanUrl = window?.location?.href.substr(
        0,
        window.location.href.indexOf("?")
      );

    const response = await callPlanApi(
      bePremium({ PlanId: selectedPlan, RedirectUrl: cleanUrl })
    );
    if (response.success) {
      window.location.href = response.data?.Url;
    }
  };

  return (
    <div
      style={{ overflowY: "hidden" }}
      className={`w-full relative rounded-2xl p-3 bg-white overflow-hidden mx-0 rtl transition-all transform ${className}`}
    >
      <img
        alt="icon"
        src={PackageIcons[data?.Id]}
        className={`mx-auto ${
          PackageIcons[data?.Id] ? "-mt-5" : "mt-2"
        } w-24 h-24`}
        loading="lazy"
      />
      <div className={"font-black text-header text-title text-center -mt-2"}>
        {data?.LevelTitle}
      </div>
      <div
        className={
          "h-px w-5/6 border dark:border-borderSolid border-borderSolid mx-auto"
        }
      />
      <div
        className={"overflow-y-auto"}
        style={{ maxHeight: "calc(100vh - 5rem - 360px)" }}
      >
        <div className={"w-5/6 mx-auto "}>
          {data?.plansItems?.map((pi) => (
            <FeatureItem data={pi} />
          ))}
        </div>
      </div>

      {!loading && (
        <div className={"grid grid-cols-2 gap-4 w-5/6 mx-auto my-4"}>
          {prices?.map((item) => (
            <CustomRadioButton
              disabled={true}
              selected={selectedPlan == item.id}
              data={item}
              onSelect={() => onPlanSelect(item)}
            />
          ))}
        </div>
      )}
      {loading && (
        <div className={"grid grid-cols-2 gap-4 w-5/6 mx-auto my-4"}>
          <Skeleton
            width={"100%"}
            height={100}
            style={{ borderRadius: "1rem" }}
          />
          <Skeleton
            width={"100%"}
            height={100}
            style={{ borderRadius: "1rem" }}
          />
        </div>
      )}

      <div className={"grid grid-cols-8 gap-4 w-5/6 mx-auto"}>
        <HamrahButton
          small
          disabled={
            loading ||
            waitingForBank ||
            waitingForBankExterntal ||
            waitingForFreeTime ||
            !data?.plansId?.includes(selectedPlan)
          }
          loading={waitingForBank || waitingForBankExterntal}
          justLoading
          className={"col-span-4 text-center"}
          onClick={() => onBuyPackage(data)}
        >
          خرید پکیج
        </HamrahButton>
        <HamrahButton
          small
          justLoading
          disabled={
            loading ||
            waitingForBank ||
            waitingForFreeTime ||
            waitingForBankExterntal
          }
          loading={waitingForFreeTime || waitingForBankExterntal}
          className={"col-span-4 text-center"}
          variant={"cancel"}
          onClick={onFreeTimeActivate}
        >
          ۳ روزه رایگان
        </HamrahButton>
      </div>
    </div>
  );
};
const CustomRadioButton = ({ selected, onSelect = () => {}, data }) => {
  return (
    <div
      onClick={() => onSelect(data)}
      className={`rounded-xl bg-highlight p-2 ${
        selected ? "border-accept border" : "hover:border-borderSolid"
      } border-transparent border flex flex-col justify-between items-center`}
    >
      <div className={"text-caption text-bodyText font-bold mb-2 text-center"}>
        {TimeTypes[data?.Duration]}
      </div>
      <div className={"text-subtitle font-bold text-title text-center"}>
        {data.Off > 0 && (
          <div
            className={
              "text-subtitle font-medium text-details text-center line-through"
            }
          >{`${toCommas(data?.Price / 10)}`}</div>
        )}
        {`${toCommas((data?.Price - data?.Off) / 10)}`}
        <br />
        تومان
      </div>

      {!selected && (
        <div className={"w-6 h-6 rounded-full bg-details mt-3 mx-auto"} />
      )}
      {selected && (
        <div
          className={
            "w-6 h-6 rounded-full bg-accept mt-3 mx-auto flex items-center justify-center"
          }
        >
          <div className={"w-2/3 h-2/3 rounded-full bg-accept-dark"} />
        </div>
      )}
    </div>
  );
};

export default MemberShipModal;
